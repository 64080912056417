<template>
  <a-modal :closable="true" centered @cancel="cancel" v-model="visable" :footer="null" class="captcha-modal">
    <center>
      <h1 style="margin-bottom: 30px">{{ $t('user.perfectInfo') }}</h1>
    </center>
    <form @submit.prevent>
      <div class="info-item">
        <span class="name">{{ $t('user.name') }}</span>
        <div class="label">
          <input type="text" v-model="user.name" />
        </div>
      </div>

      <div class="info-item">
        <span class="name">{{ $t('user.email') }}</span>
        <div class="label">
          <input type="text" v-model="user.email" />
        </div>
      </div>
      <div class="info-item">
        <span class="name">{{ $t('user.company') }}</span>
        <div class="label">
          <input type="text" v-model="user.company" />
        </div>
      </div>
      <div class="info-item">
        <span class="name">{{ $t('user.website') }}</span>
        <div class="label">
          <input type="text" v-model="user.website" />
        </div>
      </div>
      <div v-if="locale === 'zh_CN'">
        <div class="info-item" v-if="phoneEdit">
          <span class="name">{{ $t('user.phone') }}</span>
          <div class="label">
            <a-select ref="select" v-model="areaCode" style="width: 140px; margin-right: 5px">
              <a-select-option :value="item.code" v-for="item in areaCodeList" :key="item.code">{{ item.code + ' ' + item.cn }}</a-select-option>
            </a-select>
            <input type="text" style="margin-left: 5px; width: 145px; height: 32px" v-model="user.phone" autocomplete="off" />
          </div>
        </div>
        <div class="info-item" v-else>
          <span class="name">{{ $t('user.phone') }}</span>
          <div class="label">
            <span>{{ user.phone }}</span>
          </div>
        </div>
        <div class="info-item" v-if="phoneEdit">
          <span class="name">{{ $t('user.code') }}</span>
          <div class="label" style="width: 296px">
            <input type="text" v-model="user.code" style="width: 66%" autocomplete="off" />
            <button :disabled="sent" class="button button-mini" style="margin-left: 4px; vertical-align: top" @click="handleSendSMS">{{ sent ? t + 's' : $t('user.sendCode') }}</button>
          </div>
        </div>
      </div>

      <center>
        <a href="javascript:;" style="width: 79%" class="button" @click="handleUpdateUserInfo">{{ $t('common.submit') }}</a>
      </center>
    </form>
  </a-modal>
</template>
<script>
import schema from 'async-validator';
import Emitter from 'mixins/emitter';
import { mapGetters } from 'vuex';
import { deepCopy } from 'utils/util';
import { sendSMS, updateUserInfo } from 'services/user';
import areaCodeList from 'utils/areaCode.json';

export default {
  name: 'UserInfoModal',
  props: {
    modalShowAble: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    const descriptor1 = {
      oldPassword: [
        {
          type: 'string',
          required: true,
          message: this.$t('user.enterPassword'),
        },
        {
          validator: (rule, value) => value.length >= 6,
          message: this.$t('user.enterLegalPassword'),
        },
      ],
      newPassword: [
        {
          type: 'string',
          required: true,
          message: this.$t('user.enterPassword'),
        },
        {
          validator: (rule, value) => value.length >= 6,
          message: this.$t('user.enterLegalPassword'),
        },
      ],
      rePassword: {
        type: 'string',
        required: true,
        validator: (rule, value) => value.length >= 6 && value === this.resetPwd.newPassword,
        message: this.$t('user.confirmPassword'),
      },
    };
    return {
      visable: false,
      user: {},
      perfectInfoTips: this.$t('user.perfectInfo'),
      validator1: new schema(descriptor1),
      resetPwd: {
        oldPassword: '',
        newPassword: '',
        rePassword: '',
      },
      errorTips4ResetPwd: {},
      editing: false,
      editPhone: false,
      sent: false,
      t: 0,
      areaCode: '+86',
      phoneEdit: true,
      areaCodeList,
    };
  },
  mixins: [Emitter],
  computed: {
    ...mapGetters(['userInfo', 'locale']),
  },
  watch: {
    modalShowAble(val) {
      this.visable = this.modalShowAble;
    },
  },

  mounted() {
    this.user = deepCopy(this.userInfo);
    if (this.userInfo.phone) {
      this.phoneEdit = false;
    } else {
      this.phoneEdit = true;
    }
  },

  methods: {
    cancel() {
      this.$emit('changeModalData', 'false');
    },
    handleEdit(target, edit) {
      this[target] = edit;
      if (edit) {
        this.user = deepCopy(this.userInfo);
      }
    },
    handleUpdateUserInfo() {
      updateUserInfo({ ...this.user, phone: this.areaCode === '+86' ? this.user.phone : this.areaCode + this.user.phone + '' })
        .then(res => {
          this.editing = false;
          this.editPhone = false;
          this.$emit('userUpdated', 'true');
          this.$store.dispatch('initAuth');

          this.visable = false;
        })
        .catch(err => {
          this.$error({
            title: this.$t(`error['${err.response.data.errorKey}']`),
          });
          if (err.response.status === 403) {
            this.$emit('userUpdated', 'false');
            this.dispatch('account', 'error', [err]);
          }
        });
    },
    handleSendSMS() {
      this.sent = true;
      this.t = 60;
      let interval = setInterval(() => {
        if (this.t > 0) {
          this.t--;
        } else {
          this.sent = false;
          clearInterval(interval);
        }
      }, 1000);
      sendSMS({
        phone: this.areaCode === '+86' ? this.user.phone : this.areaCode + this.user.phone + '',
      }).then(res => {
        if (res.code == 0) {
          this.$success({
            title: '短信发送成功',
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import '../../assets/css/theme.less';

.c-box {
  &:not(:last-of-type) {
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 20px;

    i {
      margin-right: 8px;
    }
  }
}

.info-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  margin-left: 20px;

  .name {
    width: 72px;
    text-align: right;
    overflow: hidden;
    display: inline-block;
    color: #999;
    padding-top: 5px;

    &:after {
      content: '';
      display: inline-block;
      width: 100%;
    }
  }

  .label {
    margin-left: 30px;

    span {
      padding-top: 5px;
      padding-left: 10px;
      display: inline-block;
    }
  }

  input {
    border: 1px solid #ddd;
    padding: 5px 10px;
    border-radius: 3px;
    width: 296px;

    &:focus {
      border-color: #bbb;
    }
  }
}

.phone-check {
  line-height: 25px;
  margin-left: 100px;
  color: #999;
}

.phone-check-tips {
  font-size: 13px;
  color: #ff9900;
  margin-left: 10px;
}
</style>
