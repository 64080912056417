const lang = {
    common: {
        home: 'Home',
        price: 'Pricing',
        doc: 'API Docs',
        demo: 'Demo',
        mifengcha:'Block.cc',
        freeApiKey: 'Free Trial',
        startTrial: 'Start Trial',
        register: 'Sign Up',
        login: 'Log In',
        logout: 'Logout',
        announcement:'announcement',
        submitCryptocurrency: 'SUBMIT CRYPTOCURRENCY',
        submitExchange: 'SUBMIT EXCHANGE',
        contactus: 'CONTACT US',
        console: 'Account',
        myPackage: 'My Subscription',
        myWorkOrder: 'My Work Order',
        myOrder: 'My Order',
        dataStatistics: 'Statistics',
        userManage: 'My Profile',
        confirm: 'CONFIRM',
        cancel: 'CANCEL',
        all: 'All',
        more: 'MORE',
        submit: 'SUBMIT',
        backToHome: 'Back to the homepage',
        bottomTittle: 'Start Your Free Trial',
        emailPlacehold: 'Email Address',
        copyright: '© {year} Block.cc Inc. All Rights Reserved.',
        disclaimer: "The data provided by Block.cc comes from Internet and partners, which is irrelevant to the website. The data only for reference, not as investment proposal.",
        notFound:'The page is not found',
        disclaimerTitle:'Disclaimer',
        disclaimerContent:'The information provided on this website are sourced from third parties, which does not constitute investment advice or other sort of advice. Please use it with caution and at your own risk.',
    },
    home: {
        buildImmediately: 'Quickly get massive data of cryptocurrency markets',
        freeUse: 'Start Free Trial',
        buildIntro: 'You Can Get a {0} Cryptocurrency Market Data Source Quickly',
        stable: 'Stable',
        reliable: 'Reliable',
        lowLatency: 'Low Latency',
        massive: 'Massive',
        exchange: 'EXCHANGES',
        coin: 'CRYPTOCURRENCIES',
        currency: 'FIAT CURRENCY',
        ticker: 'TRADING PAIRS',
        history: 'HISTORICAL ROWS',
        user:'BUSINESS USERS',
        ourAdv: 'WHY CHOOSE US?',
        extensiveData: 'EXTENSIVE DATA',
        infrastructure: 'HIGH-PERFORMANCE INFRASTRUCTURE',
        security: 'SECURE ACCESS',
        adv1: 'Our wide range of market data includes cryptocurrency trade data, order book data, blockchain data and historical data. We ensure the integrity and accuracy of our data through rigorous processes.',
        adv2: 'Our infrastructure is running on multiple servers across several data centres to ensure the fastest data delivery and the lowest latency possible. We have redundant hosting and load-balanced environments for maximum reliability.',
        adv3: 'Our API endpoints are encrypted through SSL so user traffic is secured when requesting data. To ensure maximum security, we also sign the API requests and require registration and the generation of an API Key.',
        ourData: 'Professional digital currency market data',
        dt1: 'Real-time Quotes (TICKER)',
        dt2: 'Currency Price',
        dt3: 'Information Data',
        dt4: 'Exchange',
        dt5: 'Candlesticks Data',
        dt6: 'Order Book (DEPTH)',
        dt7: 'Currency Information',
        dt8: 'Blockchain Data',
        dt9: 'Exchange Rate',
        dt1_1: 'The latest price',
        dt1_2: 'Best bid / ask price',
        dt1_3: '24h high / low',
        dt1_4: 'Volume 24h, Price change',
        dt2_1: 'USD price, Bitcoin price',
        dt2_2: 'Trading volume, Market cap',
        dt2_3: 'High / Low (24h, 1 week, 1 month)',
        dt2_4: 'All-time high / low',
        dt3_1: 'Exchange announcement',
        dt3_2: 'News, Articles',
        dt3_3: 'Weibo, Twitter Kol',
        dt4_1: 'Name, Website',
        dt4_2: 'Type of exchange',
        dt4_3:'Turnover',
        dt5_1: 'Opening / Closing price',
        dt5_2: 'The highest / lowest price',
        dt5_3: 'Trading volume',
        dt6_1: 'Price',
        dt6_2: 'Hold',
        dt6_3: 'Bids / Asks list',
        dt7_1: 'Name, Logo, Official website',
        dt7_2: 'Total supply, Circulating supply',
        dt7_3: 'Whitepaper, ERC20 contract address',
        dt7_4: 'Social media links',
        dt8_1: 'Transaction count',
        dt8_2: 'Address count',
        dt9_1: 'Exchange rate of more than 100 fiat currencies',
        apiBlockTittle:'Concise API, easy to access',
        api:'API',
        api_tittle:'Concise, but powerful',
        api_1:'Well-designed',
        api_2:'SDK supported',
        api_3:'Redundant hosting, load balancing environment',
        api_4:'SSL protocol ensures data security',
        safe:'Security',
        safe_tittle:'SSL protocol encrypts network communications',
        safe_1:'Multiple servers and data centers, ensures fastest data transfers and lowest latency',
        safe_2:'Load-balanced environment, maximum reliability',
        server:'Service',
        server_tittle:'1 VS 1 service',
        server_1:'Contact us to get 1vs1 technical support',
        server_2:'Any questions can be answered and supported',
        whyChoose:'Why Choose Block.cc?',
        choose_data:'Volume Data',
        choose_data_1:'Cryptocurrency transaction data',
        choose_data_2:'Candlesticks Data',
        choose_data_3:'Depth data',
        choose_data_4:'Blockchain data',
        choose_data_5:'Historical data',
        choose_data_6:'Manual cleaning ensures data integrity and accuracy',
        choose_performance:'High Performance',
        choose_performance_1:'The infrastructure runs on multiple servers in multiple data centers, ensuring the fastest data transfer and the lowest latency',
        choose_performance_2:'Redundant hosting and load balancing environment for maximum reliability',
        choose_safe:'Safe and reliable',
        choose_safe_1:'Using SSL protocol',
        choose_safe_2:'Network communication encryption',
        choose_safe_3:'Protect data from being tampered with or stolen',
        customerTittle:'They\'re all using Block.cc',
        // imtoken:'imToken',
        // imtoken_describe:'imToken is the world\'s leading blockchain wallet, providing millions of users safe and reliable digital asset management services. imToken provides quotation services to users through Block.cc.',
        // gate:'Gate.io',
        // gate_describe:'Gate. io is an international platform for global blockchain assets. Its predecessor, the Biter, was founded in 2013.  Since its establishment, Gate.io has provided nearly 100 high-quality blockchain asset trading and investment services to millions of users from more than 130 countries around the world.  Gate. IO is committed to being a reliable, secure, stable, and reputable international station of blockchain assets. It not only provides users with safe, convenient, and fair blockchain asset transaction services but also comprehensively guarantees users\' transaction information security and asset security. Gate.io provides quotation services to users through Block.cc.',
        // huobi:'Huobi Wallet',
        // huobi_describe:'Huobi Wallet is a professional multi-currency light Wallet. Relying on Huobi Group\'s technology accumulation and security experience in the blockchain field, Huobi protects the asset security of global digital currency users from multiple dimensions and provides simple, convenient, safe, and reliable digital asset management services. Huobi Wallet provides quotation services to users through Block.cc.',
        // wallet:'Wallet.io',
        // wallet_describe:'Wallet.io is a decentralized full-currency personal wallet that currently supports almost all mainstream blockchain assets globally, including BTC, ETH, EOS, BCH, BCHSV, Tezos, and Tron. It also provides decentralized exchange services between assets and free rental services for EOS CPU resources. Wallet.io provides quotation services to users through Block.cc.',
        // antpool:'ANTPOOL',
        // antpool_describe:'ANTPOOL is an efficient digital currency mining pool, committed to providing customers with easy-to-use functions, safe and stable performance, efficient and intimate service, and rich and transparent income. ANTPOOL provides mining services for Bitcoin, Bitcoin cash, Litecoin, Ethereum, and other digital currencies. Support PPS, PPS+, PPLNS, SOLO, and other payment methods. ANTPOOL provides quotation services to users through Block.cc.',
        stepsTittle:'It’s easy to access to Block.cc',
        stepsText1:'Sign up for a Block.cc account',
        stepsBtn1:'Sign Up Free',
        stepsText2:'Choose "Free Trial" or Subscribe another plan',
        stepsBtn2:'Get API KEY',
        stepsText3:'View API KEY and access according to SDK or documentation',
        stepsBtn3:'Docs',
        stepsText4:'Don\'t hesitate to contact us if you have any questions',
        stepsBtn4:'Contact us',
        cooperationTittle:'Business Cooperation',
        cooperationText:'If you need more types of services, we provide enterprise customized services to meet the individual needs of enterprises',
        cooperationBtn:'Contact Us',
        cooperation1:'Higher frequency',
        cooperation2:'Unlimited calls',
        cooperation3:'Custom API features',
        cooperation4:'Customized service',
        cooperation5:'Advanced technical',
        cooperation6:'Individual service',
    },
    package: {
        NONE: 'NONE',
        FREE: 'FREE',
        BASIC: 'BASIC',
        PROFESSION: 'PROFESSIONAL',
        ENTERPRISE: 'ENTERPRISE',
        ULTIMATE: 'ULTIMATE',
        COOPERATION: 'COOPERATION',
        UNLIMITED: 'UNLIMITED',
        freeTrial:'Free Trial',
        type: 'Type',
        price: 'Price',
        getData:'Quickly get massive data of cryptocurrency markets',
        expireDate: 'Expire Date',
        renew: 'Renew',
        apiUsageRestrictions: 'API calls limit',
        callsPerMonth: 'calls/month',
        qps: 'QPS',
        qpsShort: '{num}',
        openData: 'Open Data',
        rateLimiting: 'Rate Limit',
        wsConnections: 'WebSocket connections',
        ws: 'WebSocket connections',
        customerSupport: 'Customer Support',
        emailSupport: 'Email Support',
        email: 'Email',
        customFunctions: 'Custom Features',
        phoneAndEmailSupport: 'Phone & Email',
        upgrade4MorePrivileges: 'Upgrade to enjoy more benefits',
        subscribe: 'Subscribe',
        used: ' (Used: {0}/{1})',
        open: 'Subscribe',
        yourApiKey: 'My API KEY',
        copy: 'Copy',
        change: 'Reset',
        copySuccuess: 'Success',
        changeSuccuess: 'Success',
        howToUse: 'How to use it?',
        viewDoc: 'View Doc',
        run: 'Try it',
        apiUsage: 'Api Usage',
        useBalance: 'Remaining calls {0}/{1}',
        exhausted: 'Your package balance has been exhausted',
        upgrade: 'Upgrade',
        usageHistory: 'Historical use',
        monthly: 'Monthly',
        annual: 'Annual',
        savemoney:'Save up to 20%',
        saveMonth:'save {num} / Month',
        cooperation: 'COOPERATION',
        deepCooperation: 'Have more needs? We provide enterprise customization services.',
        contactus: 'Contact Us',
        co1: 'Higher frequency',
        co2: 'Unlimited calls',
        co3: 'Custom API features',
        co4: 'Customized service',
        co5: 'Advanced technical',
        co6: 'Individual service',
        amount: 'Amount',
        upgradeSpread: 'Upgrade cost',
        FREE_tip:'For testing or hobby projects',
        BASIC_tip:'For small / personal projects',
        PROFESSION_tip:'For medium size organisations',
        ENTERPRISE_tip:'For large size organisations',
        ULTIMATE_tip:'For organizations needing large amounts of data.',
        times:'calls',
        days:'day(s)',
        yesterday_times:'Yesterday',
        useage_times:'Total',
        month_times:'Remainder (this month)',
        package_days:'Validity period'
    },
    workOrder: {
        PROCESSING: 'PROCESSING',
        FEEDBACK: 'FEEDBACK',
        CLOSED: 'CLOSED',
        WEBSOCKET_API_USAGE: 'WEBSOCKET API USAGE',
        REST_API_USAGE: 'REST API USAGE',
        OTHER: 'OTHER'
    },
    order: {
        UPGRADE: 'UPGRADE',
        RENEW: 'RENEW',
        NEW_PURCHASE: 'NEW PURCHASE',
        PENDING: 'PENDING',
        CONFIRMED: 'CONFIRMED',
        CANCEL: 'CANCEL',
        EXPIRED: 'EXPIRED',
        INADEQUATE: 'INADEQUATE',
        SENDBACK: 'SENDBACK',
        no: 'No',
        product: 'Subscription',
        duration: 'Duration',
        type: 'Type',
        createTime: 'Created',
        payTime: 'Payment',
        status: 'Status',
        originalPrice: 'Original price',
        payable: 'Price payable',
        costEffective: 'Total payment',
        operate: 'Operate',
        pay: 'Pay',
        void: 'Void',
        orderCanceled: 'Order cancelled',
        confirmVoidOrder: 'Are you sure to void this order?',
        perfectOrder: 'Checkout your subscription',
        current: 'Current plan',
        choosePackage: 'Selected plan',
        chooseDuration: 'Duration',
        paymentMethod: 'Payment method',
        realTimeRate: 'Real Time Exchange Rates',
        buynow: 'Subscribe Now',
        buySuccess: 'Success',
        jumpingForYou: 'Jumping for you',
        orderDetail: 'Order Details',
        paymentCoin: 'Payment currency',
        paymentInstruction: 'Payment Instruction',
        pi1: 'Please send coins to the address by client or online wallet.',
        pi2: 'The order will be completed when the transaction has been confirmed automatically after you sent the coins.',
        pi3: 'Since the cryptocurrency has a volatile value, please finish the payment in 1 hour after  making the order, or it will be cancelled.',
        pi4: 'The amount of the order does not include the gas fee, please pay it.',
        encounterProblems: 'Encounter Problems',
        paymentAddr: 'Payment address',
        copy: 'Copy',
        copySuccuess: 'Success',
        paymentFinish: 'Payment completed',
        discount: 'Annual payment {0} off ',
        save: 'Save',
        discountNum: 'Discount',
        aliPay: 'Ali Pay',
        paymentFailed: 'Payment failed? Again',
        paymentSuccessful: 'Successful',
        resetSuccess: 'Password reset complete',
        paymentWithProblem: 'Please contact {0} or BDmifengcha to solve the problem when payment is encountered.',
        process: 'Go to processing',
        tittle:'Quickly get massive data of cryptocurrency markets',
        callsPerMonth: '{0} calls/month',
        helpTip:'If you need help or learn more, please contact us.',
        freeIsUsingTip:'Your FREE has not expired yet, you can subscribe to another plan for more services.',
        freeIsOutTip:'Your FREE has expired, please upgrade your subscription.',
        freeOnlyNewTip:'FREE is only for new users, please subscribe to another plan.'
    },
    user: {
        info: 'General',
        requestLogin: 'please login',
        contact: 'Contact',
        perfectInfo: 'Complete information to get more services',
        name: 'Name',
        phone: 'Phone',
        email: 'Email',
        company: 'Company',
        website: 'Website',
        edit: 'Edit',
        securityCenter: 'Security',
        resetPassword: 'Reset',
        oldPwd: 'Current Password',
        newPwd: 'New Password',
        confirmPwd: 'Confirm Password',
        updatePwd: 'Update',
        loginTitle: 'Log In',
        password: 'Password',
        login: 'Login',
        loginNow: 'Log in',
        forgetPassword: 'Forgot your password?',
        register: 'Register',
        registerNow: 'Get an account now',
        cannotLogin: 'Unable to log in?',
        noAccount: 'Not a member yet?',
        enterEmail: 'Please enter the email address',
        enterLegalEmail: 'Email Error',
        enterPassword: 'Please enter password',
        enterLegalPassword: 'Please enter a password with a minimum length of 6',
        ncStartText: 'Hold down the slider and drag it to the end',
        ncYesText: 'Verification passed',
        registerTitle: 'Create A New Account',
        registerSubmitText: 'Create My Account',
        phone2: 'Phone',
        confirmPassword: 'Inconsistent password input twice',
        haveAnAccount: 'Already have an account? ',
        resetPwd: 'Reset Password',
        resetSuccess: 'Reset successfully',
        activatedSuccessfully: 'Activated successfully',
        activationFails: 'Activation fails',
        checkEmail: 'A registration activation email has been sent to your mailbox. If you have not received the email for a long time, please check your trash folder.',
        resetEmail: 'A reset password email has been sent to your mailbox. If you have not received the email for a long time, please check your trash folder.',
        customerService: 'Customer Service',
        findPwd: 'Retrieve password',
        verify: 'Verify',
        code: 'Code',
        sendCode: 'Get Code',
        confirmAliCode: 'Please drag the slider to verify',
        verified: 'Verified',
        unverified: 'Vnverified',
        phoneCheckTips: 'Mobile phone number is not verified, you can get more timely reminders in time through verification!',
        viewOrder: 'View Order',
        dashboard: 'Dashboard',
        jumpingForYou: 'Jumping for you',
        webhookIntroduce: 'Webhooks allow external services to be notified when certain events happen. When the specified events happen, we’ll send a POST request to each of the URLs you provide.',
        webhookEdit: 'edit',
        webhookDel: 'del',
        webhookUrl: 'WebHook Url',
        webhookDetailIntroduce: 'We’ll send a POST request to the URL below with details of any subscribed events. You can also specify which data format you’d like to receive (JSON, x-www-form-urlencoded, etc).',
        webhookEventsTip: 'Please select event:',
        webhookPriceEvent:'price',
        webhookAllEventsTip: 'All',
        webhookActive:'start',
        webhookSubmit:'Update configuration',
        bindPhone: 'Bound mobile phone has obtained more services',
        emailHasBeenSent:'Email has been sent',
        userInfoUpdateSuccess:'Update Successfully!',
        passwordChangeSuccess:'The password is changed successfully. Please log in again',
        smsSendSuccess:'SMS has been sent',
        getKeyPlaceholder:'Get my API KEY after subscription / renewal'
    },
    contactus: {
        service: 'SERVICE',
        name: 'NAME',
        phone: 'PHONE',
        company: 'COMPANY',
        email: 'EMAIL',
        wechat: 'WECHAT',
        website: 'WEBSITE',
        message: 'MESSAGE',
        submit: 'Submit Now',
        success: 'Success',
        required: 'Please enter'
    },
    time: {
        second: '{num}S',
        minute: '{num}M',
        m: '{num}M',
        hour: '{num}H',
        h: '{num}H',
        day: '{num}D',
        week: '{num}W',
        month: '{num}Month',
        year: '{num}Y',
        yearLong: 'one year | {num} years',
        ytd: 'YTD',
    },
    bottom: {
        copyRight: 'Copyrights © 2019 All Rights Reserved by Block.cc Inc.',
        view: 'The data provided by data.mifengcha.com, from the public information on the Internet and the information provided by the partners, has nothing to do with the position of the website. For user reference only, does not constitute any investment advice.',
    },
    error: {
        "error.unknow": "Unknow error",
        "error.account.email.invalid": "Email invalid",
        "error.account.password.too.week": "Password too week",
        "error.account.password.inconsistent": "Passwords entered twice are inconsistent",
        "error.account.password.entered": "Password error",
        "error.account.email.existed": "Email already used",
        "error.account.phone.existed": "Phone already used",
        "error.order.operational.failed": "Order operational failed",
        "error.ticket.operational.failed": "Ticket operational failed",
        "error.account.active-code.invalid": "Active code invalid",
        "error.account.unauthorized": "Unauthorized",
        "error.account.userInfo": 'Username or password is incorrect',
        "error.account.unactivated": "Unactivated",
        "error.account.loginWithoutActivated": "Your account has not been activated. The activation email has been sent to your email. Please login after activation",
        "error.captcha.invalid": "Captcha invalid",
        "error.order.operating.fail": "Order operating fail",
        "error.ticket.close.fail": "Ticket Close Fail",
        "error.ali.pay.fail": 'Ali Pay Fail',
        "error.ali.verification.fail": 'Ali Verification Fail',
        "error.ali.order.invalid": 'Ali Order Invalid',
        "error.ali.order.money.insufficient": 'Ali Order Money Insufficient',
        "error.order.unexpired.no.renewed": 'Unexpired and cannot be renewed',
        "error.registered.resend.email": 'Registered Resend Email',
        "error.orders.has.unpaid": 'You have an unpaid order, please go to pay or cancel!',
        "error.request.already.exist": 'The currency request is reviewing'
    },
    request: {
        cardTip: {
            link: "Link",
            desc: "Description",
            base: "Basic"
        },
        cnDescriptionTip: "Chinese description",
        enDescriptionTip: "English description",
        withoutLogin: "Please login before submitting",
        submitSuccess: "Submit Success",
        tips: "New Currency Listing",
        externalId: "Slug(id)",
        symbol: "Symbol",
        enterSymbol: "Symbol Required",
        enterWebsiteUrl: "WebSite Required",
        enterExplorerUrl: "Please enter the explorer url",
        enterLogo: "logo Required",
        enterExternalId: "Slug(id) Required",
        enterEnFullname: "English Name Required",
        enterCnFullname: "Chinese Name Required",
        enterTotalSupply: "Total Supply Required",
        enterAvailableSupply: "Available Supply Required",
        enterLegalNumber: "Please enter a legal number",
        enFullname: "English Name",
        cnFullname: "Chinese Name",
        logoUrl: "Upload Logo",
        totalSupply: "Total Supply",
        availableSupply: "Available Supply",
        websiteUrl: "Official Website  Link",
        explorerUrl: "Explorer Link",
        whitePaperUrl: "White Paper Link",
        githubUrl: "Github Link",
        twitterUrl: "Twitter Link",
        facebookUrl: "Facebook Link",
        telegramUrl: "Telegram Link",
        redditUrl: "Reddit Link",
        description: "Description",
        issueDate: "Issue Date",
        submit: "Submit",
        illegalImgType: "You can only upload JPG file!",
        illegalImgSize: "Image must smaller than 2MB!",
    },
    check: {
        nonePhoneOrCode: 'Mobile phone number or verification code is empty',
        errorPhone: 'Phone number error',
        SMSSuccess: 'SMS sent successfully',
        bindPhoneError: 'bindPhoneError',
    },
}

export default lang;
