<script>
import { on, off } from 'utils/dom';
export default {
    name: 'dropdown',
    data() {
        return {
            timeout: null,
            visible: false,
            triggerElm: null
        }
    },
    provide() {
        return {
            dropdown: this
        };
    },
    watch: {
        visible(val) {
            let dropdownMenu = this.$children.find(child => child.$options.name === 'dropdown-menu');
            if(dropdownMenu) {
                dropdownMenu.$emit('visible', val);
            }
        }
    },
    mounted() {
        this.$on('menu-item-click', this.handleMenuItemClick);
    },
    beforeDestroy() {
        off(this.triggerElm, 'mouseenter', this.show);
        off(this.triggerElm, 'mouseleave', this.hide);
        off(this.dropdownElm, 'mouseenter', this.show);
        off(this.dropdownElm, 'mouseleave', this.hide);
    },
    methods: {
        show() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.visible = true;
            }, 250);
        },
        hide() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.visible = false;
            }, 250);
        },
        initDomOperation() {
            this.dropdownElm = this.popperElm;
            this.initEvent();
        },
        initEvent() {
            this.triggerElm = this.$slots.default[0].elm;
            on(this.triggerElm, 'mouseenter', this.show);
            on(this.triggerElm, 'mouseleave', this.hide);
            on(this.dropdownElm, 'mouseenter', this.show);
            on(this.dropdownElm, 'mouseleave', this.hide);
        },
        handleMenuItemClick(command) {
            this.$emit('command', command);
            this.visible = false;
        }
    },
    render() {
        let triggerElm = this.$slots.default;
        return (
            <div class="dropdown">
                {triggerElm}
                {this.$slots.dropdown}
            </div>
        )
    }
}
</script>