<template>
    <li @click="handleClick"><slot></slot></li>
</template>
<script>
export default {
    name: 'dropdown-item',
    props: {
        command: {}
    },
    methods: {
        handleClick() {
            this.$parent.dropdown.$emit('menu-item-click', this.command);
        }
    }
}
</script>
<style lang="less" scoped>

</style>