import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './locale/index';
import VueClipboard from 'vue-clipboard2';
import CustomComponents from './plugins/components';
import { Plugin as Fragment } from 'vue-fragment';
import * as filters from './utils/filter';
import antDesignVue from './plugins/ant-design-vue';
import antDesign from 'ant-design-vue';
import './assets/css/common.less';

Vue.use(CustomComponents);
Vue.use(VueClipboard);
Vue.use(Fragment);
Vue.use(antDesign);
antDesignVue();

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

const init = async () => {
  await store.dispatch('initAuth');
  store.dispatch('initPackages');
  store.dispatch('setLocale').then(res => {
    let locale = res;
    i18n.locale = locale;
    setFavicon(locale);
    setTDK(locale);
  });
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app');
};

const setFavicon = locale => {
  let favicon = document.querySelector('link[rel="icon"]');
  if (favicon !== null) {
    favicon.href = locale === 'zh_CN' ? '/icons/favicon-zh_CN.ico' : '/icons/favicon-en_US.ico';
  } else {
    favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = locale === 'zh_CN' ? '/icons/favicon-zh_CN.ico' : '/icons/favicon-en_US.ico';
    document.head.appendChild(favicon);
  }
};

const setTDK = locale => {
  let titleMap = {
    zh_CN: 'mifengcha.com开放平台_数字货币行情API_区块链数据接口-蜜蜂查',
    en_US: 'Block.cc - Cryptocurrency & Market Data API',
  };
  let keywordsMap = {
    zh_CN: '数字货币行情API接口,交易所行情API,区块链数据API,蜜蜂查开放平台API',
    en_US: 'Cryptocurrency Data API,Crypto News API,Exchange Market API,Blockchain API,BTC,ETH,Coin,Price',
  };
  let desMap = {
    zh_CN:
      '蜜蜂查API产品为广大数字货币投资者及区块链开发者提供高效、稳定、安全的加密数字货币行情API接口接入服务，涵盖12000+币种信息、500+交易所信息、实时报价、K线数据、历史成交、交易所交易对数据、区块链数据、区块链新闻资讯等数字货币行情数据信息。蜜蜂查（mifengcha.com）是一家专注于加密数字货币行情的大数据解决方案平台和数据提供商。',
    en_US: "Get cryptocurrency's real-time quotes, order book, volume data and news through REST & WebSocket APIs.",
  };
  let title = document.querySelector('title');
  let text = document.createTextNode(titleMap[locale]);
  if (title !== null) {
    title.parentNode.removeChild(title);
  }
  title = document.createElement('title');
  title.appendChild(text);
  document.head.appendChild(title);
  let keywords = document.querySelector('meta[name="Keywords"]');
  if (keywords !== null) {
    keywords.content = keywordsMap[locale];
  } else {
    keywords = document.createElement('meta');
    keywords.name = 'Keywords';
    keywords.content = keywordsMap[locale];
    document.head.appendChild(keywords);
  }
  let des = document.querySelector('meta[name="Description"]');
  if (des !== null) {
    des.content = desMap[locale];
  } else {
    des = document.createElement('meta');
    des.name = 'Description';
    des.content = desMap[locale];
    document.head.appendChild(des);
  }
};

Vue.config.productionTip = false;
init();
