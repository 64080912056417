import Vue from 'vue'
import VueI18n from 'vue-i18n';

import zh_CN from './lang/zh_CN';
import en_US from './lang/en_US';

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'zh_CN',
    fallbackLocale: 'zh_CN',
    messages: {
        zh_CN,
        en_US
    }
});
export default i18n;