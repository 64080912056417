<template>
  <a-modal centered @cancel="cancel" v-model="visable" :footer="null" class="captcha-modal">
    <div class="c-box">
      <img
        style="width: 100%"
        src="https://mifengcha.oss-cn-beijing.aliyuncs.com/common/social/business.jpeg"
        :alt="locale === 'zh_CN' ? 'hi@mifengcha.com' : 'hi@block.cc'"
        slot="title"
        class="business"
      />
    </div>
  </a-modal>
</template>
<script>
import Emitter from 'mixins/emitter';
import { mapGetters } from 'vuex';

export default {
  name: 'contact',
  props: {
    contactModalShowAble: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      visable: false,
    };
  },
  watch: {
    contactModalShowAble(val) {
      this.visable = this.contactModalShowAble;
    },
  },
  mixins: [Emitter],
  computed: {
    ...mapGetters(['userInfo', 'locale']),
  },

  methods: {
    cancel() {
      this.$emit('changeContactData', 'false');
    },
  },
};
</script>
<style lang="less" scoped></style>
