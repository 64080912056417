<template>
  <div class="container">
    <Nav :isMove="isMove" :headDynamic="headDynamic" />
    <div class="main-content">
      <router-view />
    </div>
    <Bottom :showFreeUse="showFreeUse" />
    <!-- <div class="feedback">
            <span class="chat-btn">{{$t('user.customerService')}}</span>
        </div> -->
    <div class="fixed">
      <div class="icon-group">
<!--        <Popover v-if="locale === 'zh_CN'" placement="left">-->
<!--          <template slot="content">-->
<!--            <div v-if="meiQiaState">在线咨询</div>-->
<!--            <div v-else style="width: 220px">您好，当前无人工客服在线，建议您先留言，感谢您的理解与支持！</div>-->
<!--          </template>-->
<!--          <div id="meiqia-btn" @click="handleMeiQiaBtnClick()" class="item"><img id="meiqia-btn" src="./assets/images/icons/kefu.png" /></div>-->
<!--        </Popover>-->
<!--          隐藏微信-->
<!--        <Popover v-if="locale === 'zh_CN'" placement="left">-->
<!--          <template slot="content">-->
<!--            <img class="qrcode" src="./assets/images/wecom-qrcode.png" />-->
<!--          </template>-->
<!--          <div class="item"><img class="wechat" src="./assets/images/icons/wechat.png" /></div>-->
<!--        </Popover>-->
        <Popover placement="left">
          <template slot="content">
            <img class="qrcode" :src="locale === 'zh_CN' ? require('./assets/images/telegram-qrcode.png') : require('./assets/images/telegram-qrcode-en.png')" />
            <!-- 客服Telegram:<br /><a id="telegram-btn" class="telegram-link" href="https://t.me/mifengcha_cs" rel="nofollow" target="_blank">t.me/mifengcha_cs</a> -->
          </template>
          <a id="telegram-btn" class="item" :href="locale === 'zh_CN' ? 'https://t.me/mifengcha_cs' : 'https://t.me/Blockcc_bc'" rel="nofollow" target="_blank"
            ><img id="telegram-btn" src="./assets/images/icons/telegram.png"
          /></a>
        </Popover>
        <Popover placement="left">
          <template slot="content"> {{ locale === 'zh_CN' ? 'hi@mifengcha.com' : 'hi@block.cc' }} </template>
          <div class="item">
            <img class="email" src="./assets/images/icons/email.png" />
          </div>
        </Popover>
        <!-- <Popover placement="left">
          <template slot="content"> 联系我们 </template>
          <a class="item" href="/contactus"><img src="./assets/images/icons/form.png" /></a>
        </Popover> -->
      </div>
    </div>
    <!-- <Popover title="Title" placement="left">
      <template slot="content">
        <p>Content</p>
        <p>Content</p>
      </template>
      <a-button type="primary"> Hover me </a-button>
    </Popover> -->
  </div>
</template>
<script>
import Nav from './components/nav';
import Bottom from './components/bottom';
import { mapGetters } from 'vuex';
import Popover from 'ant-design-vue/lib/popover';
const SIGN_ID = {
  zh_CN: '37ef9b97837507c12642c8e81db4e56131c2b319a8b353601d5ba18b6dd07bc58fd2677097e1385761156dbb69da053bfcfc8d',
  en_US: '37ef9b9780200a932540cae91ee7b531f8b88e5f8e881f2a4432cdf6a1b33ada53078c1af237582a1744b8212e34dc4bc70f8e',
};
export default {
  name: 'app',
  components: {
    Nav,
    Bottom,
    Popover,
  },
  computed: {
    ...mapGetters(['locale']),
  },
  data() {
    return {
      isMove: false,
      headDynamic: true,
      headDynamicPages: ['/', '/pricing', '/order', '/payorder'],
      showFreeUse: true,
      showFreeUsePages: ['/', '/pricing', '/contactus'],
      meiQiaState: true,
    };
  },
  watch: {
    $route: {
      //监听路由是否变化
      handler(to, from) {
        if (this.headDynamicPages.includes(to.path)) {
          this.headDynamic = true;
        } else {
          this.headDynamic = false;
        }
        if (this.showFreeUsePages.includes(to.path)) {
          this.showFreeUse = true;
        } else {
          this.showFreeUse = false;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.initMeiQia();
    // this.initScript();
    window.addEventListener('scroll', this.handleScroll);
    if (this.$route.query.s) {
      let webSource = this.$route.query.s;
      gtag('event', 'page_view_by_source', { source: webSource, locale: this.locale, send_to: 'G-8Y2MZ6GZPQ' });
    }
  },
  methods: {
    initMeiQia() {
      (function (a, b, c, d, e, j, s) {
        a[d] =
          a[d] ||
          function () {
            (a[d].a = a[d].a || []).push(arguments);
          };
        (j = b.createElement(c)), (s = b.getElementsByTagName(c)[0]);
        j.async = true;
        j.charset = 'UTF-8';
        j.src = 'https://static.meiqia.com/widget/loader.js';
        s.parentNode.insertBefore(j, s);
      })(window, document, 'script', '_MEIQIA');
      _MEIQIA('entId', '23640134244c2a7edd3e82bd5fba3220');
      _MEIQIA('allSet', this.getMeiQiaState);
      _MEIQIA('withoutBtn');
      _MEIQIA('init');
    },
    handleMeiQiaBtnClick() {
      _MEIQIA('showPanel');
    },
    getMeiQiaState(servability) {
      this.meiQiaState = servability;
    },
    initScript() {
      this.script = document.createElement('script');
      this.script.type = 'text/javascript';
      this.script.src = 'https://yzf.qq.com/xv/web/static/chat_sdk/yzf_chat.min.js';
      document.body.appendChild(this.script);
      this.script.onload = () => {
        this.initChatRoom();
      };
    },
    initChatRoom() {
      window.yzf.init({
        sign: SIGN_ID[this.locale],
        uid: '',
        data: { c1: '', c2: '', c3: '', c4: '', c5: '' },
        selector: '.feedback',
        callback: function (type, data) {
          console.log(type, data);
        },
      });
    },
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop; // 滚动条偏移量
      if (scrollTop >= 60) {
        this.isMove = true;
      } else {
        this.isMove = false;
      }
    },
  },
};
</script>
<style lang="less">
@import '~ant-design-vue/lib/popover/style/index.css';
.main-content {
  padding-top: 80px;
  min-height: calc(100vh - 185px);
  background-color: #f1f1f5;
}
.feedback {
  position: fixed;
  bottom: 50px;
  right: 50px;
  .chat-btn {
    display: block;
    padding: 16px 20px;
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
    background: #f0ab39;
    color: #fff;
    position: absolute;
    z-index: 2222;
    white-space: nowrap;
    font-size: 16px;
    bottom: 0;
    right: 0;
    box-shadow: 0 2px 23px rgba(0, 0, 0, 0.2);
  }
}
.fixed {
  position: fixed;
  z-index: 99;
  bottom: 50px;
  right: 20px;
  .icon-group {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    .item {
      width: 48px;
      height: 48px;
      line-height: 48px;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
        margin: 0 auto;
      }
      .wechat {
        width: 26px !important;
        height: 26px !important;
      }
      .email {
        width: 24px !important;
        height: 24px !important;
      }
    }
    .item:not(:last-child) {
      border-bottom: 1px solid #e4e4e4;
    }
  }
}
.qrcode {
  margin: -5px -10px;
  width: 120px;
  height: 120px;
}
.telegram-link {
  &:hover {
    color: black !important;
  }
}
</style>
