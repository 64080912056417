import Vue from 'vue';
import Vuex from 'vuex';
import Cookie from 'js-cookie';
import router from '../router';
import { fetchAuth, logout } from '../services/user';
import { fetchPackagePrice } from '../services/index';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: '',
    userInfo: {},
    firstShowModal: true,
    packages: [],
    locale: '',
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      if (!Cookie.get('token')) {
        Cookie.set('token', token, {
          expires: 0.5,
        });
      }
    },
    SET_LOCALE(state, locale) {
      state.locale = locale;
      Cookie.set('locale', locale);
    },
    SET_USER(state, user) {
      state.userInfo = user;
      Cookie.set('userInfo', user, {
        expires: 0.5,
      });
    },
    LOGOUT(state) {
      state.token = '';
      state.userInfo = {};
      Cookie.remove('token');
    },
    SET_PACKAGES(state, packages) {
      state.packages = packages;
    },
    SET_FIRSTSHOWMODAL(state, firstShowModal) {
      state.firstShowModal = firstShowModal;
      Cookie.set('firstShowModal', firstShowModal);
    },
  },
  actions: {
    async initAuth({ commit, state }, refresh) {
      //   if (!Cookie.get('token') || refresh) {
      //   }
      let res = await fetchAuth();
      let userInfo = res.data.userInfo;
      commit('SET_TOKEN', res.data._csrf.token);
      // if(userInfo){
      //     if(Cookie.get('locale') === userInfo.source){
      //         commit('SET_USER', userInfo);
      //     }else{
      //         console.log('跳转')
      //         router.push('/pricing')
      //     }
      // }
      userInfo && commit('SET_USER', userInfo);
    },
    async logout({ dispatch, commit, state }) {
      await dispatch('initAuth');
      await logout();
      commit('LOGOUT');
    },
    async initPackages({ commit, state }) {
      let res = await fetchPackagePrice();
      let data = res.data;
      const level = {
        FREE: 1,
        BASIC: 2,
        PROFESSION: 3,
        ENTERPRISE: 4,
        ULTIMATE: 5,
      };
      let flag = false;
      if (state.userInfo.pricePackage) {
        flag = true;
      }
      data.forEach((item, index) => {
        item.ws = Number(item.ws);
        item.level = index + 1;
        if (flag && level[item.name] < level[state.userInfo.pricePackage]) {
          item.disabled = true;
        }
        // if (item.name == 'FREE' && !state.userInfo.freeAvailable) {
        //     item.disabled = true;
        // }
      });
      commit('SET_PACKAGES', data);
    },
    async setLocale({ commit }, locale) {
      if (!locale) {
        locale = Cookie.get('locale') || 'en_US';
        // locale = 'zh_CN';
        // locale = 'en_US';
      }
      await commit('SET_LOCALE', locale);
      return locale;
    },
    async initFirstShowModal({ commit }) {
      console.log('init FirstShowModal');
      commit('SET_FIRSTSHOWMODAL', true);
    },
    async setFirstShowModal({ commit }, firstShowModal) {
      await commit('SET_FIRSTSHOWMODAL', firstShowModal);
      return firstShowModal;
    },
  },
  getters: {
    token: state => state.token,
    userInfo: state => state.userInfo,
    packages: state => state.packages,
    locale: state => state.locale,
    firstShowModal: state => state.firstShowModal,
  },
});
