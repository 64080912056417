<script>
export default {
    name: 'tabs',
    props: {
        value: String,
        showHeader: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            panes: [],
            currentName: this.value,
        }
    },
    watch: {
        value (value) {
            this.currentName = value;
            this.$emit('input', value);
        },
    },
    mounted () {
        if (this.$slots.default) {
            const paneSlots = this.$slots.default.filter(vnode => vnode.tag &&
                vnode.componentOptions && vnode.componentOptions.Ctor.options.name === 'TabPane');
            const panes = paneSlots.map(({ componentInstance }) => componentInstance);
            this.panes = panes;
        }
    },
    methods: {
        handleTabClick (name) {
            this.currentName = name;
            this.$emit('input', name);
            this.$emit('tab-click', name)
        }
    },
    render () {
        const { panes, showHeader } = this;
        const tabs = this._l(panes, (pane, index) => {
            let tabName = pane.name || pane.index || index;
            const tabLabelContent = pane.$slots.label || pane.label;
            return (
                <li onClick={() => this.handleTabClick(tabName)} class={{ active: this.currentName === tabName, 'tab-nav-item': true }}>{tabLabelContent}</li>
            )
        });
        return (
            <div class="bl-tabs">
                {showHeader && <ul class="tab-nav">{tabs}</ul>}
                {this.$slots.default}
            </div>
        )
    }
}
</script>
<style lang="less" scoped>
@import '~assets/css/theme.less';
.tab-nav {
    border-bottom: 1px solid #ddd;
    .tab-nav-item {
        cursor: pointer;
        display: inline-block;
        border: 1px solid #ddd;
        border-left: 0;
        padding: 15px;
        text-align: center;
        box-sizing: border-box;
        transition: all @transition-time;
        margin-bottom: -1px;
        &:hover {
            color: #FF6600;
        }
        &:first-of-type {
            border-left: 1px solid #dcdfe6;
            border-radius: 4px 0 0 0;
            box-shadow: none !important;
        }
        &:last-of-type {
            border-radius: 0 4px 0 0;
        }
        &.active {
            color: #FF6600;
            border-bottom-color: #fff;
        }
    }
}
</style>
