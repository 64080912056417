import {
    ObserveVisibility
} from 'vue-observe-visibility'

const observeMixin = {
    data() {
        return {
            intersection: {
                threshold: 0.5,
            },
            observe: false
        }
    },
    directives: {
        'observe-visibility': ObserveVisibility
    },
}

export default observeMixin;
