<script>
import formatNumber from 'utils/number_util';
export default {
    name: 'Numberic',
    props: {
        data: {
            type: [Number, String]
        },
        prefix: String,
        suffix: String,
        fold: {
            type: Boolean,
            default: true
        },
        locale: String
    },
    computed: {
        number() {
            return formatNumber(this.data, this.locale, this.fold);
        }
    },
    render() {
        const { number, $slots, prefix, suffix } = this;
        return (<span>
            {prefix || $slots.prefix}
            {number.value}
            {number.sup ? <em>x10<sup>{number.e}</sup></em> : ''}
            {suffix || $slots.suffix}
        </span>)
    }
}
</script>
