<template>
    <div
        class="tab-pane"
        v-if="(!lazy || loaded) || active"
        v-show="active">
        <slot />
    </div>
</template>
<script>
export default {
    name: 'TabPane',
    props: {
        name: {
            type: String,
        },
        label: {
            type: [String, Number]
        },
        lazy: Boolean
    },
    data() {
        return {
            loaded: false
        }
    },
    computed: {
        active() {
            const active = this.$parent.currentName === (this.name || this.index);
            if (active) {
                this.loaded = true;
            }
            return active;
        },
    },
}
</script>
