<template>
  <div class="main">
    <section class="banner-block">
      <div class="banner-content">
        <div class="group-main" :class="locale === 'zh_CN' ? 'zh' : 'en'">
          <h1 class="banner-tittle">{{ $t('home.buildImmediately') }}</h1>
          <a class="free-btn" href="/pricing">{{ $t('home.freeUse') }}</a>
        </div>
        <img class="img" src="../assets/images/banner-item.png" />
      </div>
      <div class="group-block">
        <div class="group-item" v-for="(item, index) in bannerData">
          <img class="item-img" :class="locale === 'zh_CN' ? 'zh' : 'en'" :src="require('../assets/images/icons/banner-' + item.id + '.png')" />
          <div class="item-data">
            <h2 class="data-num">{{ locale === 'zh_CN' ? item.numTextZh : item.numTextEn }}</h2>
            <h3 class="data-text">{{ $t(`home.${item.id}`) }}</h3>
          </div>
        </div>
      </div>
    </section>
    <section class="data-block">
      <h2 class="tittle">{{ $t('home.ourData') }}</h2>
      <div class="group-block">
        <div class="item" v-for="(item, index) in functionData">
          <img class="icon" :src="require('../assets/images/icons/function-' + item.id + '.png')" />
          <div class="message">
            <h3 class="message-tittle">{{ $t(`home.${item.id}`) }}</h3>
            <div class="message-group" v-for="num of item.messageNum">
              <div class="message-item">
                <div class="circle"></div>
                <span class="item-text">{{ $t(`home.${item.id}_${num}`) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="api-block">
      <h2 class="tittle">{{ $t('home.apiBlockTittle') }}</h2>
      <div class="option-bar">
        <div class="option-item" :class="apiDescribeSeloected == 'api' ? 'option-item-selected' : ''" @mouseover="mouseOver('api')">{{ $t('home.api') }}</div>
        <div class="option-item" :class="apiDescribeSeloected == 'safe' ? 'option-item-selected' : ''" @mouseover="mouseOver('safe')">{{ $t('home.safe') }}</div>
        <div class="option-item" :class="apiDescribeSeloected == 'server' ? 'option-item-selected' : ''" @mouseover="mouseOver('server')">{{ $t('home.server') }}</div>
      </div>
      <div class="api-content">
        <div class="describe">
          <h3 class="describe-tittle">{{ $t(`home.${apiDescribeSeloected}_tittle`) }}</h3>
          <div class="describe-content" :class="locale === 'zh_CN' ? 'zh' : 'en'">
            <div class="describe-item" v-for="number of apiDescribeNum[apiDescribeSeloected]">
              <div class="circle"></div>
              <span class="item-text">{{ $t(`home.${apiDescribeSeloected}_${number}`) }}</span>
            </div>
          </div>
          <a class="describe-btn" href="/pricing">{{ $t('home.freeUse') }}</a>
        </div>
        <img class="img" :src="require('../assets/images/home-describe-' + apiDescribeSeloected + '.png')" />
      </div>
    </section>
    <section class="choose-block">
      <h2 class="tittle">{{ $t('home.whyChoose') }}</h2>
      <div class="choose-content">
        <div class="choose-item" v-for="(item, index) in chooseBlockNum">
          <div class="choose-left">
            <img class="choose-img" :src="require('../assets/images/choose-' + item.id + '.png')" />
            <h3 class="choose-tittle">{{ $t(`home.choose_${item.id}`) }}</h3>
          </div>
          <div class="choose-right">
            <div class="choose-li" v-for="num of item.num">
              <div class="li-circle"></div>
              <div class="li-text">{{ $t(`home.choose_${item.id}_${num}`) }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="customer-block">
      <h2 class="tittle">{{ $t('home.customerTittle') }}</h2>
      <div class="content">
        <swiper class="swiper-main" :options="swiperOption" ref="mySwiper">
          <swiper-slide class="swiper-item" v-for="(item, index) in customers" :key="index">
            <h2 class="customer-name">{{ item.name }}</h2>
            <div class="customer-describe" :class="locale === 'zh_CN' ? 'zh' : 'en'">{{ locale === 'zh_CN' ? item.desZh : item.desEn }}</div>
            <a class="use-btn" href="/pricing">{{ locale === 'zh_CN' ? '免费试用' : 'Start Free Trial' }}</a>
          </swiper-slide>
          <div class="swiper-button-prev left-arrow" slot="button-prev"></div>
          <div class="swiper-button-next right-arrow" slot="button-next"></div>
        </swiper>
      </div>
      <div class="logo-group">
        <div class="logo-circle" v-for="(item, index) in customers">
          <img class="logo-img" :src="require('../assets/images/icons/logo-' + item.id + '.png')" />
        </div>
      </div>
    </section>
    <section class="steps-block">
      <h2 class="tittle">{{ $t('home.stepsTittle') }}</h2>
      <div class="content">
        <div class="line"></div>
        <div class="steps-group">
          <div class="steps-item" v-for="(item, index) in steps" @click="handleStep(item)">
            <div class="circle">
              <div class="ball">
                <div class="ball-content">
                  <img v-if="item.indexCotent == 'contact'" class="content-duigou" src="../assets/images/icons/duigou.png" />
                  <span v-else class="content-num">{{ item.indexCotent }}</span>
                </div>
              </div>
            </div>
            <div class="text">{{ item.text }}</div>
            <div class="btn">{{ item.btn }}</div>
          </div>
        </div>
      </div>
    </section>
    <section class="cooperation-block">
      <div class="colum-left">
        <div class="item-left row-top">
          <img class="item-img" :class="locale === 'zh_CN' ? 'zh' : 'en'" src="../assets/images/cooperation-duigou.png" />
          <h3 class="item-text" :class="locale === 'zh_CN' ? 'zh' : 'en'">{{ $t('home.cooperation1') }}</h3>
        </div>
        <div class="item-left row-middle">
          <img class="item-img" :class="locale === 'zh_CN' ? 'zh' : 'en'" src="../assets/images/cooperation-duigou.png" />
          <h3 class="item-text" :class="locale === 'zh_CN' ? 'zh' : 'en'">{{ $t('home.cooperation2') }}</h3>
        </div>
        <div class="item-left row-bottom">
          <img class="item-img" :class="locale === 'zh_CN' ? 'zh' : 'en'" src="../assets/images/cooperation-duigou.png" />
          <h3 class="item-text" :class="locale === 'zh_CN' ? 'zh' : 'en'">{{ $t('home.cooperation3') }}</h3>
        </div>
      </div>
      <div class="colum-middle">
        <img class="img" src="../assets/images/cooperation.png" />
        <h2 class="tittle">{{ $t('home.cooperationTittle') }}</h2>
        <div class="text">{{ $t('home.cooperationText') }}</div>
        <a v-if="locale === 'zh_CN'" class="contact-us" href="/contactus">{{ $t('home.cooperationBtn') }}</a>
        <a v-else class="contact-us" href="https://t.me/Blockcc_bc" target="_blank" rel="nofollow">{{ $t('home.cooperationBtn') }}</a>
      </div>
      <div class="colum-right">
        <div class="item-right row-top" :class="locale === 'zh_CN' ? 'zh' : 'en'">
          <h3 class="item-text" :class="locale === 'zh_CN' ? 'zh' : 'en'">{{ $t('home.cooperation4') }}</h3>
          <img class="item-img" :class="locale === 'zh_CN' ? 'zh' : 'en'" src="../assets/images/cooperation-duigou.png" />
        </div>
        <div class="item-right row-middle" :class="locale === 'zh_CN' ? 'zh' : 'en'">
          <h3 class="item-text" :class="locale === 'zh_CN' ? 'zh' : 'en'">{{ $t('home.cooperation5') }}</h3>
          <img class="item-img" :class="locale === 'zh_CN' ? 'zh' : 'en'" src="../assets/images/cooperation-duigou.png" />
        </div>
        <div class="item-right row-bottom" :class="locale === 'zh_CN' ? 'zh' : 'en'">
          <h3 class="item-text" :class="locale === 'zh_CN' ? 'zh' : 'en'">{{ $t('home.cooperation6') }}</h3>
          <img class="item-img" :class="locale === 'zh_CN' ? 'zh' : 'en'" src="../assets/images/cooperation-duigou.png" />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import observeMixin from '../mixins/observe';
import 'swiper/dist/css/swiper.css';

let cooperate = {};
['antpool', 'gateio', 'huobiwallet', 'imtoken', 'mifengcha', 'oneboat', 'tokenclub', 'walletio'].forEach(name => {
  cooperate[name] = require(`../assets/images/cooperate/${name}.png`);
});
const wait = async ms => new Promise(resolve => setTimeout(resolve, ms));

export default {
  name: 'index',
  components: {
    swiper,
    swiperSlide,
  },
  mixins: [observeMixin],
  data() {
    return {
      height: 0,
      bannerData: [
        {
          id: 'exchange',
          numTextZh: '500+',
          numTextEn: '500+',
        },
        {
          id: 'coin',
          numTextZh: '10,000+',
          numTextEn: '10,000+',
        },
        {
          id: 'ticker',
          numTextZh: '28,000+',
          numTextEn: '28,000+',
        },
        {
          id: 'history',
          numTextZh: '100亿+',
          numTextEn: '10 Billion+',
        },
        {
          id: 'user',
          numTextZh: '5000+',
          numTextEn: '5000+',
        },
      ],
      functionData: [
        {
          id: 'dt1',
          messageNum: 4,
        },
        {
          id: 'dt2',
          messageNum: 4,
        },
        {
          id: 'dt3',
          messageNum: 3,
        },
        {
          id: 'dt4',
          messageNum: 3,
        },
        {
          id: 'dt5',
          messageNum: 3,
        },
        {
          id: 'dt6',
          messageNum: 3,
        },
        {
          id: 'dt7',
          messageNum: 4,
        },
        {
          id: 'dt8',
          messageNum: 2,
        },
        {
          id: 'dt9',
          messageNum: 1,
        },
      ],
      apiDescribeSeloected: 'api',
      apiDescribeNum: {
        api: 4,
        safe: 2,
        server: 2,
      },
      chooseBlockNum: [
        {
          id: 'data',
          num: 6,
        },
        {
          id: 'performance',
          num: 2,
        },
        {
          id: 'safe',
          num: 3,
        },
      ],
      customers: [
        {
          id: 'imtoken',
          index: 1,
          name: 'imToken',
          desZh: 'imToken是全球领先的区块链钱包，为千万用户提供安全可信赖的数字资产管理服务。imToken通过蜜蜂查API为用户提供行情服务。',
          desEn:
            "imToken is the world's leading blockchain wallet, providing millions of users safe and reliable digital asset management services. imToken provides quotation services to users through Block.cc.",
        },
        {
          id: 'gate',
          index: 2,
          name: 'Gate.io',
          desZh:
            'Gate.io是一家全球区块链资产国际站，前身比特儿于2013年成立。创办至今，Gate.io 已为来自全球超过130个国家的数百万用户，提供了近百种优质区块链资产品类的交易和投资服务。Gate.io致力于做一家值得信赖的安全、稳定、有信誉的区块链资产国际站，不仅为用户提供安全、便捷、公平的区块链资产交易服务，同时全面保障用户的交易信息安全和资产安全。Gate.io通过蜜蜂查API为用户提供行情服务。',
          desEn:
            "Gate.io is committed to being a reliable, secure, stable, and reputable international station of blockchain assets. It not only provides users with safe, convenient and fair blockchain asset transaction services, but also comprehensively guarantees users' security of transaction and asset. Gate.io provides quotation services to users through Block.cc.",
        },
        {
          id: 'huobi',
          index: 3,
          name: 'Huobi Wallet',
          desZh:
            'Huobi Wallet是一款专业的多币种轻钱包，依托火币集团在区块链领域的技术积累和安全经验，从多重维度保障全球数字货币用户的资产安全，提供简单便捷、安全可靠的数字资产管理服务。Huobi Wallet通过蜜蜂查API为用户提供行情服务。Huobi Wallet通过蜜蜂查API为用户提供行情服务。',
          desEn:
            "Huobi Wallet is a professional multi-currency light Wallet. Relying on Huobi Group's technology accumulation and security experience in the blockchain field, Huobi protects the asset security of global digital currency users from multiple dimensions and provides simple, convenient, safe, and reliable digital asset management services. Huobi Wallet provides quotation services to users through Block.cc.",
        },
        {
          id: 'wallet',
          index: 4,
          name: 'Wallet.io',
          desZh:
            'Wallet.io是一个去中心化全币种个人钱包，目前支持BTC，ETH，EOS，BCH, BCHSV，Tezos和Tron等全球的几乎所有主流区块链资产。并且提供资产之间的去中心化兑换服务，EOS CPU资源免费租赁服务。Wallet.io通过蜜蜂查API为用户提供行情服务。',
          desEn:
            'Wallet.io is a decentralized full-currency personal wallet that currently supports almost all mainstream blockchain assets globally, including BTC, ETH, EOS, BCH, BCHSV, Tezos, and Tron. It also provides decentralized exchange services between assets and free rental services for EOS CPU resources. Wallet.io provides quotation services to users through Block.cc.',
        },
        {
          id: 'antpool',
          index: 5,
          name: 'ANTPOOL',
          desZh:
            'ANTPOOL是一家高效的数字货币矿池，致力于为客户提供简单易用的功能、安全稳定的性能、高效贴心的服务、丰厚透明的收益，蚂蚁矿池提供比特币、比特币现金、莱特币、以太坊等多种数字货币的挖矿服务，支持PPS、PPS+、PPLNS、SOLO等多种付款方式。ANTPOOL通过蜜蜂查API为用户提供行情服务。',
          desEn:
            'ANTPOOL is an efficient digital currency mining pool, committed to providing customers with easy-to-use functions, safe and stable performance, efficient and intimate service, and rich and transparent income. ANTPOOL provides mining services for BTC, BCH, LTC, ETH, and other digital currencies. And it supports PPS, PPS+, PPLNS, SOLO, and other payments. ANTPOOL provides quotation services to users through Block.cc.',
        },
      ],
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 10000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      currentIndex: 0,
    };
  },
  computed: {
    ...mapGetters(['locale']),
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    steps() {
      return [
        {
          indexCotent: '1',
          text: this.$t('home.stepsText1'),
          btn: this.$t('home.stepsBtn1'),
          pathTo: 'register',
        },
        {
          indexCotent: '2',
          text: this.$t('home.stepsText2'),
          btn: this.$t('home.stepsBtn2'),
          pathTo: 'pricing',
        },
        {
          indexCotent: '3',
          text: this.$t('home.stepsText3'),
          btn: this.$t('home.stepsBtn3'),
          pathTo: 'doc',
        },
        {
          indexCotent: 'contact',
          text: this.$t('home.stepsText4'),
          btn: this.$t('home.stepsBtn4'),
          pathTo: 'contactus',
        },
      ];
    },
  },
  async mounted() {},
  beforeDestroy() {},
  methods: {
    mouseOver(value) {
      this.apiDescribeSeloected = value;
    },
    handleStep(item) {
      if (item.pathTo === 'doc') {
        window.open('https://blockcc-api-document.pages.dev/' + this.$store.state.locale);
      } else if (item.pathTo === 'contactus' && this.$store.state.locale === 'en_US') {
        window.open('https://t.me/Blockcc_bc');
      } else {
        this.$router.push('/' + item.pathTo);
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import '../assets/css/theme.less';
.main {
  background-color: rgb(248, 248, 249);
}
.slider-element {
  margin-top: -20px;
  position: relative;
  display: flex;
  align-items: center;
  background: rgb(255, 255, 255) url('../assets/images/slider.jpeg') center bottom / cover no-repeat;
  background-attachment: fixed;
}
.slider-content {
  width: @container;
  margin: 0 auto;
  .wrapper-in {
    width: 50%;
  }
  .word-place {
    position: relative;
    width: 72px;
    white-space: nowrap;
    &.en {
      width: 100px;
    }
    text-align: center;
    display: inline-block;
    vertical-align: top;
    em {
      position: absolute;
      left: 0;
      width: 100%;
    }
  }
  h2 {
    font-size: 50px;
    line-height: 1.3;
    letter-spacing: -1px;
    font-weight: 700;
    margin-bottom: 26px;
  }
  h3 {
    font-size: 18px;
    color: #aaa;
    margin-bottom: 10px;
    line-height: 1.5;
    span {
      color: @primary-color;
      margin: 0 5px;
    }
  }
}
.button-free {
  border: 1px solid #ff6a00;
  background: transparent;
  color: #ff6a00;
  &:hover {
    background: mix(#fff, #ff6a00, 90%);
  }
}
.im-token {
  height: 540px;
  background: url('../assets/images/im-token-bg.png') no-repeat -100px bottom / contain;
  .container {
    height: 100%;
    position: relative;
  }
  .phone {
    width: 100%;
    height: 100%;
    margin-left: -138px;
    background: url('../assets/images/phone.png') no-repeat center center / contain;
  }
  .desc {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    text-align: center;
    p {
      line-height: 1.8;
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    a {
      font-size: 20px;
      &:hover {
        color: @dark-color;
      }
    }
  }
}
.container {
  width: @container;
  margin: 0 auto;
}
.partner {
  height: 150px;
  width: @container;
  margin-top: auto;
  display: flex;
  align-items: center;
  padding: 34px 0;
  .swiper-slide {
    overflow: hidden;
  }
  .cooperate-logo {
    height: 32px;
    margin-left: -100px;
    filter: drop-shadow(100px 0 #222);
  }
}
.swiper-container {
  width: 100%;
  flex: 1;
}
.datas {
  background-color: #f9f9f9;
  overflow: hidden;
  .container {
    margin: 60px auto;
  }
  .data-item {
    width: 20%;
    display: inline-block;
    color: #444;
    span {
      display: block;
      text-align: center;
    }
    .icon {
      height: 60px;
      font-size: 56px;
    }
    .num {
      font-size: 42px;
      line-height: 60px;
      font-weight: 700;
    }
    .line {
      position: relative;
      margin: 20px auto 25px auto;
      width: 15%;
      border-top: 2px solid #444;
      opacity: 0.9;
    }
    .name {
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-top: 10px;
    }
  }
}
.heading-block {
  margin-bottom: 50px;
  h2 {
    font-size: 30px;
    text-align: center;
    &:after {
      content: '';
      display: block;
      margin: 30px auto 0;
      width: 40px;
      border-top: 2px solid #444;
    }
  }
}
.feature-item {
  width: calc(100% / 3);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
}
.fbox-advantage {
  min-height: 296px;
  .feature-item {
    .fbox-icon {
      position: relative;
      width: 96px;
      height: 96px;
      margin: 0 auto 30px;
      font-size: 42px;
      background-color: @primary-color;
      color: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background 0.3s;
      &:after {
        pointer-events: none;
        position: absolute;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        border-radius: 50%;
        content: '';
        top: -3px;
        left: -3px;
        padding: 3px;
        box-shadow: 0 0 0 2px #333;
        opacity: 0;
        transform: scale(0.8);
        transition: transform 0.3s, opacity 0.3s;
      }
    }
    h3 {
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 0;
      color: #333;
      &:after {
        content: '';
        display: block;
        margin: 20px auto;
        width: 30px;
        border-top: 2px solid #555;
        transition: width 0.3s ease;
      }
    }
    p {
      font-size: 16px;
      line-height: 1.8;
      color: #777;
      text-align: center;
    }
    &:hover {
      .fbox-icon {
        background-color: #333;
        &:after {
          transform: scale(1);
          opacity: 1;
        }
      }
      h3:after {
        width: 50px;
      }
    }
  }
}

.fbox-features {
  height: 450px;
  .feature-item {
    align-items: flex-start;
    margin-bottom: 50px;
    h3 {
      display: flex;
      align-items: center;
      [class^='icon-'] {
        font-size: 28px;
        margin-right: 6px;
        display: inline-block;
      }
      span {
        font-size: 15px;
      }
    }
    p {
      line-height: 1.8;
      color: #999;
      margin-top: 8px;
    }
  }
}

.slide-fade-enter-loop(@i) when (@i > 0) {
  .slide-fade-enter-loop((@i - 1)); // next iteration
  .slide-fade-enter-active:nth-of-type(@{i}) {
    transition: opacity 0.3s ease 0.01s * @i;
    span {
      transition: all 0.3s ease 0.05s + (0.01s * @i);
    }
    p {
      transition: all 0.3s ease 0.1s + (0.01s * @i);
    }
  }
}
.fade-in-down-loop(@i) when (@i > 0) {
  .fade-in-down-loop((@i - 1)); // next iteration
  .fade-in-down-enter-active:nth-of-type(@{i}) {
    transition: all 0.3s ease 0.1s * @i;
  }
}

.slide-fade-enter-loop(9);
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  span,
  p {
    transition: all 0.3s ease;
  }
}
.slide-fade-enter {
  opacity: 0;
  span,
  p {
    opacity: 0;
    transform: translateX(10px);
  }
}
.slide-fade-leave-to {
  opacity: 0;
}

.fade-in-down-loop(3);

.fade-in-down-enter {
  opacity: 0;
  transform: translateY(20px);
}
.fade-in-down-leave-to {
  opacity: 0;
}
.fade-in-down-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.banner-block {
  background-image: url('../assets/images/dark-banner.png');
  background-size: cover;
  background-position-y: -70px;
  height: 633px;
  margin-top: -80px;
  position: relative;
  .banner-content {
    width: @container;
    height: 633px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: row;
    .group-main {
      display: flex;
      flex-direction: column;
      width: 540px;
      position: relative;
      margin-left: 30px;
      align-items: flex-start;
      &.zh {
        top: 230px;
      }
      &.en {
        top: 200px;
      }
      .banner-tittle {
        color: white;
        font-size: 36px;
        line-height: 50px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
      }
      .free-btn {
        position: relative;
        color: white;
        background-color: #ffa800;
        height: 40px;
        border-radius: 2px;
        margin-top: 50px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        text-align: center;
        line-height: 40px;
        padding: 0 30px;
        cursor: pointer;
      }
    }
    .img {
      margin-top: 73px;
      margin-left: 250px;
      width: 413px;
      height: 405px;
    }
  }
  .group-block {
    height: 110px;
    width: 100%;
    background: rgba(0, 0, 0, 0.36);
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .group-item {
      width: calc(@container / 5);
      display: flex;
      flex-direction: row;
      align-items: center;
      .item-img {
        width: 44px;
        height: 44px;
        &.zh {
          margin-left: 38px;
        }
        &.en {
          margin-left: 30px;
        }
      }
      .item-data {
        display: flex;
        flex-direction: column;
        margin-left: 19px;
        .data-num {
          font-size: 22px;
          font-family: Arial;
          color: #ffffff;
        }
        .data-text {
          margin-top: 10px;
          margin-left: 1px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          opacity: 0.5;
        }
      }
    }
    .group-item:not(:last-child) {
      border-right: solid 1px rgba(255, 255, 255, 0.14);
    }
  }
}
.data-block {
  width: @container;
  display: flex;
  margin: 99px auto 0;
  flex-direction: column;
  align-items: center;
  .tittle {
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1e2133;
  }
  .group-block {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      display: flex;
      flex-direction: row;
      width: calc((@container / 3) - 20px);
      height: 172px;
      background: #ffffff;
      box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.07);
      border-radius: 8px;
      margin-top: 34px;
      .icon {
        width: 70px;
        height: 70px;
        margin: auto 35px;
      }
      .message {
        display: flex;
        flex-direction: column;
        margin-top: 39px;
        .message-tittle {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #1e2133;
          margin-bottom: 15px;
        }
        .message-group {
          display: flex;
          flex-direction: column;
          .message-item {
            display: flex;
            flex-direction: row;
            position: relative;
            width: calc((@container / 3) - 175px);
            .circle {
              width: 4px;
              height: 4px;
              background: #51535d;
              border-radius: 50%;
              position: absolute;
              top: 8px;
            }
            .item-text {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #1e2133;
              margin-left: 10px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
.api-block {
  width: @container;
  margin: 90px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 500px;
  .tittle {
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1e2133;
  }
  .option-bar {
    width: @container;
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: solid 1px #d0d0d1;
    .option-item {
      width: 50px;
      text-align: center;
      font-size: 14px;
      font-family: Arial;
      font-weight: bold;
      padding-bottom: 30px;
      color: #6f7276;
      cursor: pointer;
    }
    .option-item:not(:last-child) {
      margin-right: 127px;
    }
    .option-item-selected {
      color: #ffa800 !important;
      border-bottom: solid 2px #ffa800;
    }
  }
  .api-content {
    margin: 60px auto 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 238px;
    .describe {
      display: flex;
      flex-direction: column;
      width: 300px;
      position: relative;
      .describe-tittle {
        font-size: 18px;
        line-height: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #1e2133;
      }
      .describe-content {
        display: flex;
        flex-direction: column;
        &.zh {
          margin-top: 24px;
        }
        &.en {
          margin-top: 18px;
        }
        .describe-item {
          display: flex;
          flex-direction: row;
          position: relative;
          margin-bottom: 8px;
          .circle {
            width: 4px;
            height: 4px;
            background: #51535d;
            border-radius: 50%;
            position: absolute;
            top: 6px;
          }
          .item-text {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #1e2133;
            margin-left: 10px;
            line-height: 17px;
          }
        }
      }
      .describe-btn {
        width: 138px;
        height: 40px;
        background: #ffa800;
        border-radius: 2px;
        color: white;
        text-align: center;
        line-height: 40px;
        position: absolute;
        bottom: 20px;
        cursor: pointer;
        font-size: 16px;
      }
    }
    .img {
      margin: -10px 0 0 30px;
      width: 366px;
      height: 230px;
    }
  }
}
.choose-block {
  background: #efeff4;
  display: flex;
  flex-direction: column;
  align-items: center;
  .tittle {
    margin-top: 46px;
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1e2133;
  }
  .choose-content {
    margin-top: 71px;
    margin-bottom: 73px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .choose-item {
      display: flex;
      flex-direction: row;
      .choose-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        .choose-img {
          width: 90px;
          height: 90px;
        }
        .choose-tittle {
          margin-top: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #6b6b6b;
        }
      }
      .choose-right {
        width: 215px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 33px;
        height: 128px;
        .choose-li {
          position: relative;
          .li-circle {
            width: 4px;
            height: 4px;
            background: #51535d;
            border-radius: 50%;
            position: absolute;
            top: 6px;
          }
          .li-text {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #1e2133;
            line-height: 16px;
            margin-left: 10px;
            margin-bottom: 8px;
          }
        }
      }
    }
    .choose-item:not(:last-child) {
      margin-right: 100px;
    }
  }
}
.customer-block {
  background-image: url('../assets/images/customer-background.png');
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  .tittle {
    margin-top: 61px;
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
  .content {
    height: 450px;
    width: 822px;
    .swiper-main {
      .swiper-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        vertical-align: center;
        justify-content: center;
        height: 410px;
        .customer-name {
          font-size: 24px;
          font-family: Arial;
          font-weight: bold;
          color: #ffffff;
        }
        .customer-describe {
          width: 473px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 24px;
          &.zh {
            margin: 45px 0;
          }
          &.en {
            margin: 30px 0;
          }
        }
        .use-btn {
          width: 138px;
          height: 40px;
          background: #ffa800;
          border-radius: 2px;
          color: white;
          text-align: center;
          line-height: 40px;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
  }
  .logo-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 66px;
    .logo-circle {
      width: 55px;
      height: 55px;
      background: #ffffff;
      border: 1px solid #30303a;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .logo-img {
        max-height: 75%;
        max-width: 70%;
        margin: 0 auto;
      }
    }
    .logo-circle:not(:last-child) {
      margin-right: 55px;
    }
  }
}
.steps-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .tittle {
    margin-top: 99px;
    font-size: 28px;
    font-family: Arial;
    font-weight: 400;
    color: #1e2133;
  }
  .content {
    width: @container;
    margin-top: 63px;
    position: relative;
    .line {
      z-index: 1;
      width: 100%;
      height: 2px;
      background: #ececf0;
      position: absolute;
      top: 34px;
    }
    .steps-group {
      z-index: 2;
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;
      .steps-item {
        width: 191px;
        height: 214px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        .circle {
          width: 62px;
          height: 62px;
          border: 1px solid #dbdbdb;
          border-radius: 50%;
          .ball {
            width: 44px;
            height: 44px;
            background-color: #e0e0e6;
            border-radius: 50%;
            margin: 8px;
            line-height: 44px;
            .ball-content {
              position: relative;
              .content-duigou {
                position: absolute;
                top: 10px;
                left: 10px;
                width: 25px;
                height: 25px;
                filter: brightness(50%);
              }
              .content-num {
                font-size: 18px;
                font-family: Arial;
                font-weight: 900;
                color: #95959b;
              }
            }
          }
        }
        .text {
          margin-top: 32px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #1e2133;
          line-height: 22px;
        }
        .btn {
          position: absolute;
          bottom: 0;
          width: 106px;
          height: 40px;
          border: 1px solid #bbbbbc;
          border-radius: 2px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          color: #71727a;
          line-height: 40px;
        }
      }
      .steps-item:not(:last-child) {
        margin-right: 67px;
      }
      .steps-item:hover {
        .circle {
          border-color: rgb(255, 158, 41);
          .ball {
            background-color: rgb(255, 158, 41);
            .content-duigou {
              filter: brightness(100%);
            }
            .content-num {
              color: white;
            }
          }
        }
        .btn {
          background: #ffa800;
          border: 1px solid #ffa800;
          color: white;
        }
      }
    }
  }
}
.cooperation-block {
  margin-top: 110px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  .colum-middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    .img {
      width: 143px;
      height: 151px;
    }
    .tittle {
      margin-top: 32px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #1e2133;
    }
    .text {
      margin-top: 17px;
      width: 227px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1e2133;
      line-height: 18px;
    }
    .contact-us {
      margin-top: 28px;
      margin-bottom: 100px;
      width: 110px;
      height: 36px;
      border: 1px solid #ffa800;
      border-radius: 2px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      color: #ffa800;
      line-height: 36px;
    }
  }
  .colum-left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .row-top {
      margin-top: 50px;
      margin-right: 102px;
    }
    .row-middle {
      margin-top: 63px;
      margin-right: 61px;
    }
    .row-bottom {
      margin-top: 63px;
      margin-right: 102px;
    }
    .item-img {
      width: 32px;
      height: 32px;
      &.zh {
        margin-left: 23px;
      }
      &.en {
        margin-left: 15px;
      }
    }
    .item-text {
      &.zh {
        font-size: 18px;
      }
      &.en {
        font-size: 15px;
      }
      margin-left: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1e2133;
    }
  }
  .colum-right {
    .row-top {
      margin-top: 50px;
      margin-left: 104px;
      &.zh {
        padding-left: 42px;
      }
      &.en {
        padding-left: 32px;
      }
    }
    .row-middle {
      margin-top: 64px;
      margin-left: 62px;
      &.zh {
        padding-left: 42px;
      }
      &.en {
        padding-left: 32px;
      }
    }
    .row-bottom {
      margin-top: 64px;
      margin-left: 104px;
      &.zh {
        padding-left: 32px;
      }
      &.en {
        padding-left: 45px;
      }
    }
    .item-img {
      width: 32px;
      height: 32px;
      &.zh {
        margin-left: 13px;
      }
      &.en {
        margin-right: 15px;
        margin-left: auto;
      }
    }
    .item-text {
      &.zh {
        font-size: 18px;
      }
      &.en {
        font-size: 15px;
      }
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1e2133;
    }
  }
  .item-left {
    background-image: url('../assets/images/cooperation-left.png');
    background-repeat: no-repeat;
    width: 223px;
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .item-right {
    background-image: url('../assets/images/cooperation-right.png');
    background-repeat: no-repeat;
    width: 223px;
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.left-arrow {
  height: 36px !important;
  width: 36px !important;
  //  margin-top: -12px!important;
  background-image: url('../assets/images/icons/left-arrow.png');
}
.right-arrow {
  height: 36px !important;
  width: 36px !important;
  //  margin-top: -12px!important;
  background-image: url('../assets/images/icons/right-arrow.png');
}
</style>
