import Vue from 'vue';
import {
    Tooltip,
    message,
    Modal,
    Select,
    Spin,
    Pagination,
    Popover,
    Menu
} from 'ant-design-vue';

export default () => {
    Vue.use(Tooltip);
    Vue.use(Modal);
    Vue.use(Select);
    Vue.use(Spin);
    Vue.use(Pagination);
    Vue.use(Popover);
    Vue.use(Menu);
    Vue.prototype.$message = message;
    Vue.prototype.$confirm = Modal.confirm;
    Vue.prototype.$success = Modal.success;
    Vue.prototype.$error = Modal.error;
}
