import Vue from 'vue';
import Dropdown from 'components/dropdown/dropdown.vue';
import DropdownMenu from 'components/dropdown/dropdownMenu.vue';
import DropdownItem from 'components/dropdown/dropdownItem.vue';
import Tabs from 'components/tabs/tabs.vue';
import TabPane from 'components/tabs/tabPane.vue';
import Numberic from 'components/numberic';

const components = [
    Dropdown, DropdownMenu, DropdownItem, Tabs, TabPane, Numberic
]
components.forEach(component => {
    Vue.component(component.name, component);
});
export default {
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Tabs,
    TabPane
}
