import $http from '../utils/request';

export const fetchAuth = async () => await $http.get('authentication');

export const login = async params => await $http.post('login', params);

export const logout = async params => await $http.post('logout', params);

export const register = async params => await $http.post('register', params);

export const changePassword = async params => await $http.post('password/change', params);

export const checkEmail = async params => await $http.get('email/check', {
    params
});

export const resetPassword = async params => await $http.post('password/reset', params);

export const updateUserInfo = async params => await $http.post('information/change', params, {
    headers: {
        'Content-Type': 'application/json',
    }
});

export const checkPhone = async params => await $http.post('phone/active', params);

export const sendSMS = async params => await $http.get('sms/obtain', {
    params
});

export const active = async params => await $http.get('active', {
    params
});

export const updateApiKey = async params => await $http.get('updateApiKey', {params});
