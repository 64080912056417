<template>
  <div>
    <div class="explain">
      <span class="exemption">{{ $t('common.disclaimerTitle') }}:</span>{{ $t('common.disclaimerContent') }}
    </div>
    <div class="main-nav" :class="headDynamic ? (isMove ? 'black-background' : 'lucency-background') : 'black-background'">
      <div class="wrapper-in">
        <div class="left-side">
          <a href="/" class="logo">
            <img v-if="locale === 'zh_CN'" src="../assets/images/logo.png" alt="block.cc" />
            <img v-else src="../assets/images/logo-en.png" alt="block.cc" />
            <!-- <img :src="locale === 'zh_CN' ? 'https://mifengcha.oss-cn-beijing.aliyuncs.com/web/logo-name.svg' : 'https://mifengcha.oss-cn-beijing.aliyuncs.com/web/logo-name-en.svg'" alt="蜜蜂查" class="text"/> -->
            <span>{{ locale === 'zh_CN' ? '蜜蜂查API' : 'Block.cc' }}</span>
          </a>
          <router-link to="/" class="nav-link">{{ $t('common.home') }}</router-link>
          <router-link :to="{ name: 'pricing' }" class="nav-link">{{ $t('common.price') }}</router-link>
          <doc-url class="nav-link">{{ $t('common.doc') }}</doc-url>
          <a :href="locale === 'zh_CN' ? 'https://mifengcha.com/' : 'https://block.cc/'" class="nav-link" target="_blank" @click="handleJumpToDemo">{{ $t('common.demo') }}</a>
<!--          <router-link v-if="locale === 'zh_CN'" to="/announcement" class="nav-link" target="_blank">{{ $t('common.announcement') }}</router-link>-->
<!--          <a v-if="locale === 'zh_CN'" href="https://mifengcha.com/news/61177580279b01192f63561c" class="nav-link" target="_blank">帮助中心</a>-->
          <router-link to="/pricing" class="active">{{ $t('common.freeApiKey') }}</router-link>
          <!-- <div class="tip">NEW</div>   -->
        </div>
        <div class="right-side">
          <!-- <router-link class="nav-link" to="/account/dashboard">{{ $t('common.console') }}</router-link>
          <template v-if="isEmpty(userInfo)">
            <router-link class="nav-link" :to="{ name: 'login' }">{{ $t('common.login') }}</router-link>
            <router-link class="nav-link" :to="{ name: 'register' }">{{ $t('common.register') }}</router-link>
          </template> -->
          <!-- <dropdown v-if="!isEmpty(userInfo)">
            <a href="javascript:;" class="nav-link">
              {{ userInfo.email }}
              <i class="icon-angle-down" style="margin-left: 5px"></i>
            </a>
            <dropdown-menu slot="dropdown">
              <dropdown-item class="locale-item">
                <router-link to="/account/dashboard" class="nav-link menu-item">{{ $t('common.myPackage') }}</router-link>
              </dropdown-item>
              <dropdown-item class="locale-item">
                <router-link to="/account/order" class="nav-link menu-item">{{ $t('common.myOrder') }}</router-link>
              </dropdown-item>
              <dropdown-item class="locale-item">
                <router-link to="/account/user" class="nav-link menu-item">{{ $t('common.userManage') }}</router-link>
              </dropdown-item>
              <dropdown-item class="locale-item">
                <a href="javascript:;" class="nav-link menu-item" @click="logout">{{ $t('common.logout') }}</a>
              </dropdown-item>
            </dropdown-menu>
          </dropdown> -->
          <!-- <dropdown class="nav-dropdown" @command="handleLocaleChange">
            <a class="nav-lang">
              {{ currentLocale.label }}
              <i class="icon-angle-down" style="margin-left: 5px"></i>
            </a>
            <dropdown-menu slot="dropdown" class="nav-dropdown-menu">
              <dropdown-item class="locale-item" v-for="item in locales" :key="item.lang" :command="item.lang">
                <span>{{ item.label }}</span>
              </dropdown-item>
            </dropdown-menu>
          </dropdown> -->
          <div v-if="isEmpty(userInfo)" class="nav-account">
            <div class="nav-btn-login" @click="toLogin()">
              {{ $t('common.login') }}
            </div>
            <div class="nav-btn-register" @click="toRegister()">
              {{ $t('common.register') }}
            </div>
          </div>
          <dropdown v-if="!isEmpty(userInfo)" class="nav-dropdown">
            <a class="nav-console">
              <!-- <img :src="currentLocale.flag" :alt="currentLocale.label" class="flag"> -->
              {{ $t('common.console') }}
              <i class="icon-angle-down" style="margin-left: 5px"></i>
            </a>
            <dropdown-menu slot="dropdown" class="nav-dropdown-menu">
              <dropdown-item class="locale-item">
                <router-link to="/account/dashboard" class="console-link">{{ $t('common.myPackage') }}</router-link>
              </dropdown-item>
              <dropdown-item class="locale-item">
                <router-link to="/account/order" class="console-link">{{ $t('common.myOrder') }}</router-link>
              </dropdown-item>
              <dropdown-item class="locale-item">
                <router-link to="/account/user" class="console-link">{{ $t('common.userManage') }}</router-link>
              </dropdown-item>
              <dropdown-item class="locale-item">
                <a class="console-link" @click="logout()">{{ $t('common.logout') }}</a>
              </dropdown-item>
            </dropdown-menu>
          </dropdown>
          <!-- <div>
            <user-info-modal :modalShowAble="modalShowAble" @userUpdated="userUpdated" @changeModalData="changeModalData"></user-info-modal>
            <contact :contactModalShowAble="contactModalShowAble" @changeContactData="changeContactData"></contact>
            <a class="nav-link transform" @click="showModal">
              <i class="icon-wechat"></i>
              <span class="text" style="height: 40px">{{ $t('user.customerService') }}</span>
            </a>
          </div> -->

          <!-- <div>
            <a class="nav-link transform" :href="locale === 'zh_CN' ? 'mailto:hi@mifengcha.com' : 'mailto:hi@block.cc'">
              <i class="icon-mail-alt"></i>
              <span class="text">{{locale === 'zh_CN' ? 'hi@mifengcha.com' : 'hi@block.cc'}}</span>
            </a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { isEmpty } from '../utils/util';
import docUrl from '../components/doc';
import UserInfoModal from '../components/account/userinfomodal';
import Contact from '../components/account/contact';

const cn = require('../assets/images/flags/cn.svg');
const en = require('../assets/images/flags/us.svg');
export default {
  name: 'nav-bar',
  components: {
    docUrl,
    UserInfoModal,
    Contact,
  },
  data() {
    return {
      sticky: false,
      visible: false,
      //控制对话框
      modalShowAble: false,
      contactModalShowAble: false,
      locales: [
        {
          flag: cn,
          lang: 'zh_CN',
          label: '简体中文',
        },
        {
          flag: en,
          lang: 'en_US',
          label: 'English',
        },
      ],
    };
  },
  mounted() {},
  props: {
    isMove: {
      type: Boolean,
      default: false,
    },
    headDynamic: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['userInfo', 'locale']),
    currentLocale() {
      return this.locales.find(item => item.lang === this.locale) || {};
    },
  },
  methods: {
    isEmpty(target) {
      return isEmpty(target);
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({ name: 'index' });
    },
    showModal() {
      if (isEmpty(this.userInfo)) {
        this.$error({
          title: this.$t('user.requestLogin'),
        });
        this.$router.push({ name: 'login' });
      } else {
        if (this.userInfo.company == null || this.userInfo.company == '' || this.userInfo.website == null || this.userInfo.website == '' || this.userInfo.phone == null || this.userInfo.phone == '') {
          this.modalShowAble = true;
          this.contactModalShowAble = false;
        } else {
          this.modalShowAble = false;
          this.contactModalShowAble = true;
        }
      }
    },
    changeModalData(data) {
      if (data === 'false') {
        this.modalShowAble = false;
      } else {
        this.modalShowAble = true;
      }
    },
    changeContactData(data) {
      if (data === 'false') {
        this.contactModalShowAble = false;
      } else {
        this.contactModalShowAble = true;
      }
    },
    userUpdated(data) {
      if (data === 'false') {
        this.contactModalShowAble = false;
      } else {
        this.contactModalShowAble = true;
      }
    },
    async handleLocaleChange(locale) {
      await this.$store.dispatch('setLocale', locale);
      location.reload();
    },
    toLogin() {
      this.$router.push({ name: 'login' });
    },
    toRegister() {
      this.$router.push({ name: 'register' });
    },
    handleJumpToDemo() {
      if (this.locale === 'zh_CN') {
        gtag('event', 'jump_to_demo', { web_name: 'mifengcha', locale: this.locale, send_to: 'G-8Y2MZ6GZPQ' });
      } else {
        gtag('event', 'jump_to_demo', { web_name: 'blockcc', locale: this.locale, send_to: 'G-8Y2MZ6GZPQ' });
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import '../assets/css/theme.less';
@nav-height: 60px;
@explain-height: 30px;

.explain {
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  height: @explain-height;
  z-index: 333;
  width: 100%;
  font-size: 14px;
  color: #1e2133;
  display: flex;
  justify-content: center;
  align-items: center;
  .exemption {
    color: #c4192d;
    margin-right: 5px;
  }
}
.main-nav {
  width: 100%;
  position: fixed;
  top: @explain-height;
  left: 0;
  z-index: 333;
  height: @nav-height;
  .wrapper-in {
    width: @container;
    margin: 0 auto;
    display: flex;
    height: 100%;
  }
}
.black-background {
  background-color: rgba(7, 7, 21, 0.84);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.59);
}
.lucency-background {
  background-color: rgba(7, 7, 21, 0);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0);
}
.nav-link {
  display: inline-block;
  margin-left: 40px;
  line-height: 59px;
  padding: 0 10px;
  font-weight: 400;
  // transition: color @transition-time linear;
  font-size: 16px;

  height: 59px;
  color: white;
  &:hover,
  &.router-link-exact-active {
    border-bottom: solid 2px white;
  }
  .text {
    margin-left: 5px;
  }
  .flag {
    display: inline-block;
    width: 24px;
  }
}
.active {
  display: inline-block;
  margin-left: 40px;
  line-height: 59px;
  padding: 0 10px;
  font-weight: 400;
  // transition: color @transition-time linear;
  font-size: 16px;
  height: 59px;
  color: #ffa800;
}
.common-link {
  display: inline-block;
  margin-left: 40px;
  line-height: 59px;
  padding: 0 10px;
  font-weight: 400;
  // transition: color @transition-time linear;
  font-size: 16px;
  height: 59px;
  color: white;
}
.nav-lang {
  margin-right: 28px;
  width: 58px;
  height: 14px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 12px;
}
.nav-console {
  margin-right: 28px;
  width: 58px;
  height: 14px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 12px;
}
.console-link:hover {
  color: #444;
}
.nav-dropdown-menu {
  position: relative;
  left: -15px !important;
  top: 40px !important;
}
.locale-item {
  display: flex;
  img {
    width: 20px;
    margin-right: 5px;
  }
}
.transform {
  span {
    display: inline-block;
    width: 0;
    overflow: hidden;
    vertical-align: middle;
    transition: width @transition-time linear;
  }
  &:first-of-type:hover {
    span {
      width: 90px;
    }
  }
  &:nth-of-type(2):hover {
    span {
      width: 120px;
    }
  }
}
.menu-item {
  &:hover {
    color: @dark-color;
  }
}

.left-side {
  display: inline-flex;
  align-items: center;
  height: 100%;
  position: relative;
  // .tip{
  //     position: absolute !important;
  //     font-size: 12px;
  //     background-color: red;
  //     color: white;
  //     left: 580px;
  //     top: 23px;
  //     padding: 2px 3px;
  // }
}
.right-side {
  margin-left: auto;
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.logo {
  display: inline-flex;
  align-items: center;
  // width: 48px;
  height: 28px;
  transition: all 0.2s linear 0s;
  margin-right: 28px;
  color: #ffffff;
  img:first-of-type {
    width: 28px;
    height: 100%;
    filter: drop-shadow();
  }
  .text {
    height: 24px;
    margin-left: 8px;
  }
  span {
    font-size: 22px;
    margin-left: 8px;
    font-weight: 200;
    letter-spacing: 1px;
  }
}
.button {
  margin-left: 50px;
}
.version-item {
  width: 100px;
  display: flex;
  align-items: center;
  .v-sub-name {
    margin-left: auto;
    background-color: #999;
    display: inline-block;
    height: 20px;
    padding: 0 5px;
    line-height: 19px;
    font-size: 12px;
    color: #fff;
    border: 1px solid #999;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
    &.stable {
      background-color: @primary-color;
      border-color: @primary-color;
    }
  }
  &:hover {
    color: #222;
  }
}
.business {
  width: 138px;
  height: 138px;
  margin: 10px;
}
.nav-account {
  width: 200px;
  display: flex;
  flex-direction: row;
}
.nav-btn-login {
  text-align: center;
  color: #fff;
  font-size: 14px;
  width: 90px;
  height: 34px;
  border: 1px solid rgba(255, 255, 255, 0.42);
  border-radius: 2px;
  line-height: 30px;
  cursor: pointer;
}
.nav-btn-register {
  margin-left: 16px;
  text-align: center;
  color: #fff;
  background-color: #ffa800;
  font-size: 14px;
  width: 90px;
  height: 34px;
  border: 1px solid #ffa800;
  border-radius: 2px;
  line-height: 30px;
  cursor: pointer;
}
</style>
