const lang = {
    common: {
        home: '首页',
        price: '价格',
        doc: '开发文档',
        demo: '数据示例',
        mifengcha:'蜜蜂查',
        freeApiKey: '免费试用',
        startTrial: '免费试用',
        register: '注册',
        login: '登录',
        logout: '退出登录',
        announcement:'公告',
        submitCryptocurrency: '币种收录申请',
        submitExchange: '交易所收录申请',
        contactus: '联系我们',
        console: '控制中心',
        myPackage: '我的套餐',
        myWorkOrder: '我的工单',
        myOrder: '我的订单',
        dataStatistics: '数据统计',
        userManage: '账号管理',
        confirm: '确认',
        cancel: '取消',
        all: '全部',
        more: '更多',
        submit: '提交',
        backToHome: '回到首页',
        bottomTittle: '立即体验蜜蜂查API服务',
        emailPlacehold: '请输入邮箱',
        copyright: '版权所有©{year}蜜蜂查Inc.保留所有权利',
        disclaimer: "蜜蜂查所提供的数据，来自互联网信息以及合作方提供的信息，<br>与网站立场无关。仅供用户参考，不构成任何投资建议。",
        notFound:'您访问的页面不存在！',
        disclaimerTitle:'免责声明',
        disclaimerContent:'蜜蜂查API作为数据平台，所提供的所有数据均来源于第三方，仅供用户学习及研究之用，不构成任何投资、法律等其他领域的建议和依据。您需谨慎使用相关数据，并自行承担所带来的一切风险。',
    },
    home: {
        buildImmediately: '快速获取海量加密货币行情数据',
        freeUse: '免 费 试 用',
        buildIntro: '你可以快速地获得一个 {0} 的加密货币行情系统',
        stable: '稳定',
        reliable: '高可用',
        lowLatency: '低延迟',
        massive: '海量数据',
        exchange: '交易所',
        coin: '加密货币',
        currency: '法定货币',
        ticker: '交易对',
        history: '历史数据',
        user:'商业用户',
        ourAdv: '我们的优势',
        extensiveData: '海量数据',
        infrastructure: '高性能',
        security: '安全可靠',
        adv1: '我们的数据包括加密货币交易数据，K线数据，深度数据，区块链数据，历史数据。并且通过人工清洗确保数据的完整性和准确性。',
        adv2: '我们的基础架构在多个数据中心的多个服务器上运行，以确保最快的数据传输和最低的延迟。 我们拥有冗余托管和负载均衡环境，可实现最高可靠性。',
        adv3: '我们的API采用SSL协议实现网络通信加密，保障数据在传输的过程中不被篡改或窃取。',
        ourData: '专业的数字货币行情数据',
        dt1: '实时报价 (TICKER)',
        dt2: '币种价格',
        dt3: '资讯数据',
        dt4: '交易所',
        dt5: 'K线数据(OHLCV)',
        dt6: '深度盘口(DEPTH)',
        dt7: '币种信息',
        dt8: '区块数据',
        dt9: '法币汇率',
        dt1_1: '最新价',
        dt1_2: '买一/卖一价',
        dt1_3: '24h 最高/最低价',
        dt1_4: '24h交易量、涨跌幅',
        dt2_1: '美元价格、比特币价格',
        dt2_2: '交易量、市值',
        dt2_3: '最高/低价 (24h、1周、1月)',
        dt2_4: '历史最高/低价',
        dt3_1: '交易所公告',
        dt3_2: '媒体快讯/新闻',
        dt3_3: '大V 微博/twitter',
        dt4_1: '名称、网址',
        dt4_2: '交易所类型',
        dt4_3:'成交额',
        dt5_1: '开/收盘价',
        dt5_2: '最高/低价',
        dt5_3: '交易量',
        dt6_1: '价格',
        dt6_2: '持单量',
        dt6_3: '买/卖列表',
        dt7_1: '名称、图标、官网',
        dt7_2: '总量、流通总量',
        dt7_3: '白皮书、ERC20合约地址',
        dt7_4: '社交媒体链接',
        dt8_1: '链上交易数',
        dt8_2: '链上地址数',
        dt9_1: '超100种法币汇率',
        apiBlockTittle:'API设计简洁明了，几分钟即可接入',
        api:'API',
        api_tittle:'易用、强大的API',
        api_1:'良好的接口设计',
        api_2:'SDK支持',
        api_3:'冗余的托管与负载均衡环境',
        api_4:'SSL协议保障数据安全',
        safe:'安全',
        safe_tittle:'采用SSL协议实现网络通信加密',
        safe_1:'基础架构在多个数据中心的多个服务器上运行，确保最快的数据传输和最低的延迟',
        safe_2:'冗余的托管和负载均衡环境，可实现最高可靠性',
        server:'服务',
        server_tittle:'一对一的技术服务',
        server_1:'联系客服即可获得一对一的技术支持',
        server_2:'任何问题均可获得解答与支持',
        whyChoose:'为什么选择蜜蜂查',
        choose_data:'海量数据',
        choose_data_1:'加密货币交易数据',
        choose_data_2:'K线数据',
        choose_data_3:'深度数据',
        choose_data_4:'区块链数据',
        choose_data_5:'历史数据',
        choose_data_6:'人工清洗确保数据的完整性和准确性',
        choose_performance:'高性能',
        choose_performance_1:'基础架构在多个数据中心的多个服务器上运行，确保最快的数据传输和最低的延迟',
        choose_performance_2:'冗余的托管和负载均衡环境，可实现最高可靠性',
        choose_safe:'安全可靠',
        choose_safe_1:'采用SSL协议',
        choose_safe_2:'网络通信加密',
        choose_safe_3:'保障数据不被篡改或窃取',
        customerTittle:'他们都在用蜜蜂查API',
        // imtoken:'imToken',
        // imtoken_describe:'imToken是全球领先的区块链钱包，为千万用户提供安全可信赖的数字资产管理服务。imToken通过蜜蜂查API为用户提供行情服务。',
        // gate:'Gate.io',
        // gate_describe:'Gate.io是一家全球区块链资产国际站，前身比特儿于2013年成立。创办至今，Gate.io 已为来自全球超过130个国家的数百万用户，提供了近百种优质区块链资产品类的交易和投资服务。Gate.io致力于做一家值得信赖的安全、稳定、有信誉的区块链资产国际站，不仅为用户提供安全、便捷、公平的区块链资产交易服务，同时全面保障用户的交易信息安全和资产安全。Gate.io通过蜜蜂查API为用户提供行情服务。',
        // huobi:'Huobi Wallet',
        // huobi_describe:'Huobi Wallet是一款专业的多币种轻钱包，依托火币集团在区块链领域的技术积累和安全经验，从多重维度保障全球数字货币用户的资产安全，提供简单便捷、安全可靠的数字资产管理服务。Huobi Wallet通过蜜蜂查API为用户提供行情服务。Huobi Wallet通过蜜蜂查API为用户提供行情服务。',
        // wallet:'Wallet.io',
        // wallet_describe:'Wallet.io是一个去中心化全币种个人钱包，目前支持BTC，ETH，EOS，BCH, BCHSV，Tezos和Tron等全球的几乎所有主流区块链资产。并且提供资产之间的去中心化兑换服务，EOS CPU资源免费租赁服务。Wallet.io通过蜜蜂查API为用户提供行情服务。',
        // antpool:'ANTPOOL',
        // antpool_describe:'ANTPOOL是一家高效的数字货币矿池，致力于为客户提供简单易用的功能、安全稳定的性能、高效贴心的服务、丰厚透明的收益，蚂蚁矿池提供比特币、比特币现金、莱特币、以太坊等多种数字货币的挖矿服务，支持PPS、PPS+、PPLNS、SOLO等多种付款方式。ANTPOOL通过蜜蜂查API为用户提供行情服务。',
        stepsTittle:'3步轻松接入蜜蜂查',
        stepsText1:'注册蜜蜂查API账号',
        stepsBtn1:'免费注册',
        stepsText2:'选择“免费试用”或购买其他套餐并支付',
        stepsBtn2:'获取API KEY',
        stepsText3:'控制台查看API KEY，并根据SDK或文档接入',
        stepsBtn3:'帮助文档',
        stepsText4:'开始使用蜜蜂查API，如有问题？',
        stepsBtn4:'联系我们',
        cooperationTittle:'商业合作',
        cooperationText:'需要更多？我们提供企业定制服务，可满足企业个性化需求',
        cooperationBtn:'联系我们',
        cooperation1:'更高推送频率',
        cooperation2:'无限使用次数',
        cooperation3:'API定制服务',
        cooperation4:'系统定制服务',
        cooperation5:'高级技术支持',
        cooperation6:'更多个性化服务',
    },
    package: {
        NONE: '无',
        FREE: '体验版',
        BASIC: '基础版',
        PROFESSION: '专业版',
        ENTERPRISE: '企业版',
        ULTIMATE: '旗舰版',
        COOPERATION: '商业合作',
        UNLIMITED: '内部系统',
        type: '套餐类型',
        price: '价格',
        getData:'快速获取海量加密货币行情数据',
        upgrade4MorePrivileges: '升级享受更多特权',
        expireDate: '到期时间',
        renew: '续费',
        apiUsageRestrictions: 'API使用限制',
        callsPerMonth: '次/月',
        qps: '每秒查询率(次/秒)',
        qpsShort: '{num} 次/秒',
        openData: '开放数据',
        rateLimiting: '调用次数',
        wsConnections: 'WS连接数',
        ws: 'WebSocket连接数',
        customerSupport: '客服支持',
        emailSupport: '邮件支持',
        email: '邮件',
        customFunctions: '定制版',
        phoneAndEmailSupport: '电话与邮件支持',
        freeTrial:'免费试用',
        subscribe: '立即购买',
        used: ' (已使用情况: {0}/{1})',
        open: '开通套餐',
        yourApiKey: '您的API Key',
        copy: '复制',
        change: '重设',
        copySuccuess: '复制成功',
        changeSuccuess: '重设成功',
        howToUse: '如何使用',
        viewDoc: '查看文档',
        run: '测试',
        apiUsage: 'API使用情况',
        useBalance: '已使用 {0}/{1}',
        exhausted: '您的套餐余额已用尽',
        upgrade: '套餐升级',
        usageHistory: '历史使用记录',
        monthly: '月付',
        annual: '年付',
        savemoney: '省20%',
        saveMonth:'省 {num} /月',
        cooperation: '商业合作',
        deepCooperation: '需要更多？我们提供企业定制服务，可满足企业个性化需求',
        contactus: '联系我们',
        co1: '更高的推送频率',
        co2: '无限使用次数',
        co3: 'API定制服务',
        co4: '系统定制服务',
        co5: '高级技术支持',
        co6: '更多个性化服务',
        amount: '套餐金额',
        upgradeSpread: '升级差价',
        FREE_tip:'适合测试、个人爱好者使用',
        BASIC_tip:'适合经营小型非商业项目的初创型团队或个人',
        PROFESSION_tip:'适合经营中型(非)商业项目的团队',
        ENTERPRISE_tip:'适合经营大型(非)商业项目的团队',
        ULTIMATE_tip:'适合需要大量数据访问的大型企业组织或机构',
        times:'次',
        days:'天',
        yesterday_times:'昨日调用次数',
        useage_times:'套餐总调用次数',
        month_times:'本月剩余调用次数',
        package_days:'套餐有效期'
    },
    workOrder: {
        PROCESSING: '正在为您处理',
        FEEDBACK: '待你反馈',
        CLOSED: '工单关闭',
        WEBSOCKET_API_USAGE: 'WebSocketAPI使用',
        REST_API_USAGE: 'RestAPI使用',
        OTHER: '其他'
    },
    order: {
        UPGRADE: '升级',
        RENEW: '续费',
        NEW_PURCHASE: '新购',
        PENDING: '待支付',
        CONFIRMED: '已支付',
        CANCEL: '作废',
        EXPIRED: '已过期',
        INADEQUATE: '支付不足',
        SENDBACK: '人工打回',
        no: '编号',
        product: '产品',
        duration: '时长',
        type: '类型',
        createTime: '创建时间',
        payTime: '支付/开通时间',
        status: '状态',
        originalPrice: '原价',
        payable: '应付金额',
        costEffective: '支付金额',
        operate: '操作',
        pay: '支付',
        void: '作废',
        orderCanceled: '订单已取消',
        confirmVoidOrder: '确定作废该订单？',
        perfectOrder: '完善订单',
        current: '当前套餐',
        choosePackage: '选择套餐',
        chooseDuration: '购买时长',
        paymentMethod: '支付方式',
        realTimeRate: '实时汇率',
        buynow: '立即购买',
        buySuccess: '购买成功',
        jumpingForYou: '正在为您跳转',
        orderDetail: '订单详情',
        paymentCoin: '支付币种',
        paymentInstruction: '付款说明',
        pi1: '请通过客户端或在线钱包将您需要相应币种数目发送到该地址。',
        pi2: '发送完成后，系统会自动在此交易获得相应数量确认后将该订单自动开通。',
        pi3: '由于数字货币汇率浮动较大，请在创建订单之后1小时完成付款，如果没有在1小时内付款订单将自动作废。',
        pi4: '订单金额不包括数字货币矿工费，请合理支付矿工费。',
        encounterProblems: '遇到问题',
        paymentAddr: '付款地址',
        copy: '复制',
        copySuccuess: '复制成功',
        paymentFinish: '付款完成',
        discount: '包年立享 {0} 折',
        save: '省',
        discountNum: '优惠',
        aliPay: '支付宝',
        paymentFailed: '支付失败？再来一次',
        paymentSuccessful: '付款成功',
        paymentWithProblem: '付款遇到问题请{0}或者联系BDmifengcha微信解决问题。',
        process: '前往处理',
        tittle:'快速获取海量加密货币行情数据',
        callsPerMonth: '{0} 次/月',
        helpTip:'如需帮助或了解更多，请扫页面右下方二维码联系客服',
        freeIsUsingTip:'您的体验版未到期，请选择其他套餐',
        freeIsOutTip:'您的体验版已过期，请升级套餐',
        freeOnlyNewTip:'体验版仅供新用户使用，请选择其他套餐'
    },
    user: {
        info: '基本信息',
        requestLogin:'请先登录',
        contact: '联系方式',
        perfectInfo: '请完善资料，以获得更多服务',
        name: '姓名',
        phone: '手机',
        email: '邮箱',
        company: '公司',
        website: '公司网址',
        edit: '编辑信息',
        securityCenter: '安全中心',
        oldPwd: '当前密码',
        newPwd: '新密码',
        confirmPwd: '确认密码',
        updatePwd: '更新密码',
        loginTitle: '登录你的账号',
        password: '密码',
        login: '登录',
        loginNow: '立即登录',
        forgetPassword: '忘记密码?',
        register: '注册',
        registerNow: '立即注册',
        cannotLogin: '无法登录',
        noAccount: '还没有账号？',
        enterEmail: '请输入邮箱',
        enterLegalEmail: '请输入合法的邮箱',
        enterPassword: '请输入密码',
        enterLegalPassword: '请输入6位以上密码',
        ncStartText: '请按住滑块，拖动到最右边',
        ncYesText: '验证通过',
        registerTitle: '注册',
        registerSubmitText: '同意并注册',
        phone2: '手机号码',
        confirmPassword: '两次密码输入不一致',
        haveAnAccount: '已有账号？',
        resetPwd: '重置密码',
        resetSuccess: '密码修改成功',
        activatedSuccessfully: '激活成功',
        activationFails: '激活失败',
        checkEmail: '一封注册激活邮件已发至您的邮箱，如果您长时间未收到邮件，请检查您的垃圾箱邮件夹。',
        resetEmail: '一封重置密码邮件已发至您的邮箱，如果您长时间未收到邮件，请检查您的垃圾箱邮件夹。',
        customerService: '联系客服',
        findPwd: '找回密码',
        verify: '验证',
        code: '验证码',
        sendCode: '获取验证码',
        confirmAliCode: '请拖动滑块验证',
        verified: '已验证',
        unverified: '未验证',
        phoneCheckTips: '手机号码未验证，通过验证可以及时获取更多及时提醒！',
        viewOrder: '查看订单',
        dashboard: '个人中心',
        jumpingForYou: '正在为您跳转',
        webhookIntroduce: 'webhook允许在某些事件发生时通知外部服务。当指定的事件发生时，我们将向您提供的每个url发送POST请求。',
        webhookEdit: '修改',
        webhookDel: '删除',
        webhookUrl: 'WebHook Url',
        webhookDetailIntroduce: '我们将向下面的URL发送POST请求，其中包含任何订阅事件的详细信息。您还可以指定要接收的数据格式（JSON、x-www-form-urlencoded等）。',
        webhookEventsTip: '请选择事件：',
        webhookPriceEvent:'价格',
        webhookAllEventsTip: '全选',
        webhookActive:'启动',
        webhookSubmit:'更新配置',
        bindPhone: '绑定手机以获得更多服务',
        emailHasBeenSent:'邮件已发送',
        userInfoUpdateSuccess:'用户信息更新成功',
        passwordChangeSuccess:'密码修改成功，请重新登录',
        smsSendSuccess:'短信发送成功',
        getKeyPlaceholder:'开通/续费套餐后生成您的专属API KEY'
    },
    time: {
        second: '{num}秒',
        minute: '{num}分钟',
        m: '{num}分',
        hour: '{num}小时',
        h: '{num}时',
        day: '{num}天',
        week: '{num}周',
        month: '{num}月',
        year: '{num}年',
        yearLong: '{num}年',
        ytd: '今年',
    },
    contactus: {
        service: '咨询服务',
        name: '姓名',
        phone: '手机',
        company: '公司名称',
        email: '邮箱地址',
        wechat: '微信号',
        website: '公司网站',
        message: '留言',
        success: '提交成功',
        required: '请输入'
    },
    bottom: {
        copyRight: '版权所有©2022 蜜蜂查 Inc.保留所有权利',
        view: '蜜蜂查所提供的数据，来自互联网信息以及合作方提供的信息，与网站立场无关。仅供用户参考，不构成任何投资建议。',
    },
    error: {
        "error.unknow": "未知错误",
        "error.account.email.invalid": "邮箱不合法",
        "error.account.password.too.week": "密码太弱",
        "error.account.password.inconsistent": "密码两次输入不一致",
        "error.account.password.entered": "密码输入错误",
        "error.account.email.existed": "邮箱已被使用",
        "error.account.phone.existed": "手机号已被使用",
        "error.order.operational.failed": "订单操作失败",
        "error.ticket.operational.failed": "工单操作失败",
        "error.account.active-code.invalid": "Active code invalid",
        "error.account.unauthorized": "未授权",
        "error.account.userInfo": '用户名或密码错误',
        "error.account.unactivated": "未激活",
        "error.account.loginWithoutActivated": "您的账号未激活，激活邮件已经发送到您的邮箱，请激活之后再登录",
        "error.captcha.invalid": "验证码不合法",
        "error.order.operating.fail": "订单操作失败",
        "error.ticket.close.fail": "工单关闭失败",
        "error.ali.pay.fail": '支付宝支付失败',
        "error.ali.verification.fail": '支付宝验证失败',
        "error.ali.order.invalid": '支付宝订单不合法',
        "error.ali.order.money.insufficient": 'Ali Order Money Insufficient',
        "error.order.unexpired.no.renewed": "续费失败，免费版未到期",
        "error.registered.resend.email": '邮箱已经注册，激活邮件已重新发送。',
        "error.orders.has.unpaid": '您有未支付的订单，请前往支付或者取消！',
        "error.request.already.exist": '该币种已经在审核中'
    },
    request:{
        cardTip:{
            link:"链接",
            desc:"描述信息",
            base:"基本信息"
        },
        cnDescriptionTip:"中文描述",
        enDescriptionTip:"英文描述",
        withoutLogin:"请登陆之后再提交",
        submitSuccess:"提交成功",
        tips:"新币上线",
        externalId:"Slug(id)",
        symbol:"币种简称",
        enterSymbol:"请输入币种简称",
        enterWebsiteUrl:"请输入官网链接",
        enterExplorerUrl:"请输入区块浏览器链接",
        enterLogo:"请选择logo",
        enterExternalId:"请输入Slug(id)",
        enterEnFullname:"请输入币种英文全称",
        enterCnFullname:"请输入币种中文全称",
        enterTotalSupply:"请输入当前总量",
        enterAvailableSupply:"请输入流通量",
        enterLegalNumber:"请输入合法数字",
        enFullname:"英文全称",
        cnFullname:"中文全称",
        logoUrl:"上传图标",
        totalSupply:"总量",
        availableSupply:"流通量",
        websiteUrl:"官网链接",
        explorerUrl:"区块浏览器链接",
        whitePaperUrl:"白皮书链接",
        githubUrl:"Github链接",
        twitterUrl:"Twitter链接",
        facebookUrl:"Facebook链接",
        telegramUrl:"Telegram链接",
        redditUrl:"Reddit链接",
        description:"描述",
        issueDate:"发行日期",
        submit:"提交",
        illegalImgType:"请选择图片类型的文件",
        illegalImgSize:"请选择小于2M的图片",
    },
    check: {
        nonePhoneOrCode: '手机号或验证码为空',
        errorPhone: '手机号错误',
        SMSSuccess: '短信发送成功',
        bindPhoneError: '绑定手机失败',
    },
}

export default lang;
