import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '../views/index.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register.vue'),
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('../views/pricing.vue'),
  },
  {
    path: '/account/checkEmail',
    name: 'account-checkEmail',
    component: () => import('../views/checkEmail.vue'),
  },
  {
    path: '/account/changePassword',
    name: 'account-changePassword',
    component: () => import('../views/changePassword.vue'),
  },
  {
    path: '/account/:name',
    name: 'account-name',
    component: () => import('../views/account.vue'),
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/order.vue'),
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('../views/payment.vue'),
  },
  {
    path: '/email/active',
    name: 'email-active',
    component: () => import('../views/checkCode.vue'),
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: () => import('../views/contactUs.vue'),
  },
  {
    path: '/paySuccess',
    name: 'pay-success',
    component: () => import('../views/paySuccess.vue'),
  },
  {
    path: '/request/currency',
    name: 'request-currency',
    component: () => import('../views/requestCurrency.vue'),
  },
  {
    path: '/announcement',
    name: 'announcement',
    component: () => import('../views/announcement.vue'),
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/agreement.vue'),
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import('../views/redirect.vue'),
  },
  {
    path: '*',
    component: () => import('../views/notFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;
