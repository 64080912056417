<template>
  <div class="bottom">
    <!-- <div class="wrapper-in">
      <div class="col">
        <p>{{ $t('bottom.copyRight') }}</p>
        <p>{{ $t('bottom.view') }}</p>
      </div>
      <div class="col">
        <a href="/">{{ $t('common.home') }}</a>
        <a href="//mifengcha.mikecrm.com/Zha7vqZ">{{ $t('common.submitCryptocurrency') }}</a>
        <a href="//cn.mikecrm.com/TNFyHPJ">{{ $t('common.submitExchange') }}</a>
        <a href="/pricing">{{ $t('common.price') }}</a>
        <doc-url>{{ $t('common.doc') }}</doc-url>
        <a href="https://mifengcha.com">{{ $store.state.locale === 'zh_CN' ? '蜜蜂查' : 'Block.cc' }}</a>
      </div>
    </div> -->
    <div v-if="showFreeUse" class="top-block">
      <h2 class="tittle">{{ $t('common.bottomTittle') }}</h2>
      <div class="free">
        <input type="text" v-model="email" class="text-input" :placeholder="$t('common.emailPlacehold')" />
        <router-link class="button" :to="{ name: 'register', query: { email: email } }">{{ $t('common.startTrial') }}</router-link>
      </div>
    </div>
    <div class="bottom-block">
      <div class="bottom-box">
        <div class="box-layout-left">
          <div class="link-group">
            <a href="/">{{ $t('common.home') }}</a
            ><span v-if="locale === 'zh_CN'">/</span><a v-if="locale === 'zh_CN'" href="//mifengcha.mikecrm.com/Zha7vqZ">{{ $t('common.submitCryptocurrency') }}</a
            ><span v-if="locale === 'zh_CN'">/</span><a v-if="locale === 'zh_CN'" href="//cn.mikecrm.com/TNFyHPJ">{{ $t('common.submitExchange') }}</a
            ><span>/</span><a href="/pricing">{{ $t('common.price') }}</a
            ><span>/</span><doc-url>{{ $t('common.doc') }}</doc-url
            ><span v-if="locale === 'zh_CN'">/</span><a :href="locale === 'zh_CN' ? 'https://mifengcha.com' : 'https://block.cc/'" target="_blank" v-if="locale === 'zh_CN'">{{ $t('common.mifengcha') }}</a>
          </div>
          <div class="text-group" :class="locale === 'zh_CN' ? 'zh' : 'en'">
            <span class="text">{{ $t('common.copyright', { year: year }) }}</span>
            <span class="text" v-html="$t('common.disclaimer')"></span>
          </div>
        </div>
        <div class="box-layout-right">
          <div class="qrcode-group">
<!--            <div class="qrcode-item" v-if="locale === 'zh_CN'">-->
<!--              <img class="qrcode-img" src="../assets/images/wecom-qrcode.png" />-->
<!--              <span class="qrcode-tip">联系微信客服</span>-->
<!--            </div>-->
            <div class="qrcode-item">
              <img class="qrcode-img" :src="locale === 'zh_CN' ? require('../assets/images/telegram-qrcode.png') : require('../assets/images/telegram-qrcode-en.png')" />
              <span class="qrcode-tip">{{ locale === 'zh_CN' ? '联系Telegram客服' : 'Contact Us' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import docUrl from '../components/doc';
export default {
  components: {
    docUrl,
  },
  props: {
    showFreeUse: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['locale']),
  },
  data() {
    return {
      email: '',
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style lang="less" scoped>
@import '../assets/css/theme.less';
.bottom {
  .top-block {
    height: 256px;
    background-color: #2e3444;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tittle {
      margin: 51px auto 0 auto;
      // width: 312px;
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
    .free {
      margin: 69px auto 0 auto;
      .text-input {
        background-color: white;
        width: 471px;
        height: 41px;
        border-radius: 2px;
        padding-left: 24px;
      }
      .button {
        margin-left: 19px;
        background-color: #ffa800;
        border-radius: 2px;
        height: 41px;
        // width: 114px;
        text-align: center;
        color: white;
        font-size: 16px;
        font-weight: 200;
      }
    }
  }
  .bottom-block {
    height: 220px;
    background-color: #222735;
    .bottom-box {
      display: flex;
      flex-direction: row;
      width: @container;
      margin: 0 auto;
      .box-layout-left {
        display: flex;
        flex-direction: column;
        .text-group {
          display: flex;
          flex-direction: column;
          width: 400px;
          &.zh {
            margin-top: 22px;
            line-height: 30px;
          }
          &.en {
            margin-top: 22px;
            line-height: 25px;
          }
          .text {
            font-size: 14px;
            color: #b8b9bf;
          }
        }
        .link-group {
          margin-top: 30px;
          margin-left: -10px;
          a {
            color: #b8b9bf;
            font-size: 12px;
            margin: 0 10px;
          }
          span {
            color: #b8b9bf;
            font-size: 12px;
          }
        }
      }
      .box-layout-right {
        margin-left: auto;
        display: flex;
        flex-direction: row;
        .qrcode-group {
          margin-top: 30px;
          margin-left: 60px;
          display: flex;
          flex-direction: row;
          .qrcode-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 20px;
            .qrcode-img {
              width: 115px;
              height: 115px;
            }
            .qrcode-tip {
              margin-top: 15px;
              font-size: 14px;
              color: #b8b9bf;
            }
          }
        }
      }
    }
  }
}
.wrapper-in {
  width: @container;
  margin: 0 auto;
  height: 100%;
  .col {
    &:first-of-type {
      width: 40%;
    }
    &:last-of-type {
      width: 60%;
    }
    display: inline-flex;
    vertical-align: top;
    padding: 70px 0;
    height: 100%;
    p {
      line-height: 1.8;
    }
    a {
      margin-right: 10px;
      white-space: nowrap;
      &:not(:last-of-type)::after {
        content: '/';
        margin-left: 10px;
      }
      &:hover {
        color: #222;
      }
    }
    &:first-of-type {
      justify-content: center;
      flex-direction: column;
    }
    &:last-of-type {
      justify-content: flex-end;
    }
  }
}
</style>
