import qs from 'qs';
import axios from 'axios';
import store from '../store/index';

const instance = axios.create({
    baseURL: '/rest/account/',
    headers: {
        'X-CSRF-TOKEN': ''
    },
    transformRequest: [(data) => qs.stringify(data)]
});

instance.interceptors.request.use(config => {
        // 在发送请求之前做些什么
        let token = store.state.token;
        if (config.method === 'post') {
            if (config.headers['Content-Type'] === 'application/json') {
                config.transformRequest = [data => JSON.stringify(data)];
            } else {
                config.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
            }

        }
        config.headers['X-CSRF-TOKEN'] = token;
        return config;
    },

    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }

);

// 添加响应拦截器
instance.interceptors.response.use(response => {
        // 对响应数据做点什么
        return response.data;
    },

    function (error) {
        // 对响应错误做点什么
        return Promise.reject(error);
    }

);

export default instance;
