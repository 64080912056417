const zhDigits = {
    '万亿': 1e12,
    '亿': 1e8,
    '万': 1e4,
};

const enDigits = {
    'P': 1e15,
    'T': 1e12,
    'B': 1e9,
    'M': 1e6,
    'K': 1e3,
};

const DIGITS = 4;
const NOT_FOLD_LENGTH = 6;

function toDecimalPrecision(numberFloat, digits, symbol = '') {
    let numberStr = numberFloat.toPrecision(digits);
    if (numberStr.indexOf('e') < 0) {
        return {
            value: symbol + Number(numberStr),
            sup: false,
            e: 0
        };
    }
    let [n, d] = numberStr.split('e');
    if (d <= -7) {
        return {
            value: symbol + n,
            sup: true,
            e: d
        }
    }
    d = Math.abs(d) + digits - 1;
    numberStr = Number(numberStr).toFixed(d);
    numberStr = numberStr.replace(/(0+)$/g, '');
    return {
        value: symbol + numberStr,
        sup: false,
        e: 0
    };
}

const formatNumber = (number, locale = 'zh', isFold = true) =>
{
    if (number === 0) {
        return {
            value: 0,
            sup: false,
            e: 0
        };
    }
    if (!number || !isFinite(number)) {
        return {
            value: '--',
            sup: false,
            e: 0
        };
    }
    let symbol = '';

    if (parseFloat(number) < 0) {
        symbol = '-';
        number = Math.abs(number);
    }
    let numberFloat = parseFloat(number);
    if (isFold === false) {
        let numberStr = '';
        if (numberFloat >= 1) {
            numberStr = numberFloat.toString();
            let intPart = numberStr.split('.')[0];
            if (intPart.length >= NOT_FOLD_LENGTH) {
                numberStr = intPart;
            } else {
                numberStr = numberStr.substring(0, NOT_FOLD_LENGTH + 1);
            }
            return {
                value: symbol + numberStr,
                sup: false,
                e: 0
            };
        } else {
            return toDecimalPrecision(numberFloat, NOT_FOLD_LENGTH, symbol);
        }
    }
    if (numberFloat <= 1e3) {
        return toDecimalPrecision(numberFloat, DIGITS, symbol);
    }
    let numStr = numberFloat.toPrecision(DIGITS);
    let map = {};
    if (locale.indexOf('zh') > -1) {
        map = zhDigits;
    } else {
        map = enDigits;
    }
    for (const [sign, len] of Object.entries(map)) {
        if (numStr >= len) {
            numStr = numStr / len;
            return {
                value: symbol + numStr + sign,
                sup: false,
                e: 0
            }
        }
    }

    return {
        value: symbol + numStr,
        sup: false,
        e: 0
    }

}
;

export default formatNumber;
