const lang = {
    zh_CN: {
        months: '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split(
            '_'
        ),
        monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split(
            '_'
        ),
        weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
        weekdaysShort: '周日_周一_周二_周三_周四_周五_周六'.split('_'),
        time_tt_a: '上午',
        time_tt_p: '下午',
        time_TT_a: '上午',
        time_TT_p: '下午',
        time_mins_ago: '分钟前',
        time_hours_ago: '小时前',
        time_days_ago: '天前',
        time_months_ago: '月前',
        time_years_ago: '年前',
        time_just_now: '刚刚',
        week: num => `第${num}周`
    },
    zh_HK: {
        months: '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split(
            '_'
        ),
        monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split(
            '_'
        ),
        weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
        weekdaysShort: '週日_週一_週二_週三_週四_週五_週六'.split('_'),
        time_tt_a: '上午',
        time_tt_p: '下午',
        time_TT_a: '上午',
        time_TT_p: '下午',
        time_mins_ago: '分钟前',
        time_hours_ago: '小时前',
        time_days_ago: '天前',
        time_months_ago: '月前',
        time_years_ago: '年前',
        time_just_now: '刚刚',
        week: num =>
            `第${num}周`
    },
    en_US: {
        months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split(
            '_'
        ),
        monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split(
            '_'
        ),
        weekdays: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split(
            '_'
        ),
        weekdaysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
        time_tt_a: 'am',
        time_tt_p: 'pm',
        time_TT_a: 'AM',
        time_TT_p: 'PM',
        time_just_now: 'Just now',
        time_mins_ago: 'mins ago',
        time_hours_ago: 'hours ago',
        time_days_ago: 'days ago',
        time_months_ago: 'months ago',
        time_years_ago: 'years ago',
        week: num =>
            {
                switch (num) {
                    case 1:
                        return `1st week`;
                    case 2:
                        return `2nd week`;
                    case 3:
                        return `3rd week`;
                    default:
                        return `${num}th week`;
                }
            }
    },
    ko_KR: {
        months: '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split('_'),
        monthsShort: '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split(
            '_'
        ),
        weekdays: '일요일_월요일_화요일_수요일_목요일_금요일_토요일'.split('_'),
        weekdaysShort: '일_월_화_수_목_금_토'.split('_'),
        time_tt_a: '오전',
        time_tt_p: '오후',
        time_TT_a: '오전',
        time_TT_p: '오후',
        time_just_now: '그냥',
        time_mins_ago: '몇분 전에',
        time_hours_ago: '시간 전',
        time_days_ago: '며칠 전에',
        time_months_ago: '몇달 전',
        time_years_ago: '몇 년 전',
        week: num =>
            `제 ${num} 주`
    }
};
export const moment = function (val, formatStr, locale = 'zh_CN') {
    if (!val || val < 0) return;
    const date = new Date(val);
    const zeroize = function (value, length) {
        if (!length) {
            length = 2;
        }
        value = String(value);
        for (var i = 0, zeros = ''; i < length - value.length; i++) {
            zeros += '0';
        }
        return zeros + value;
    };
    return formatStr.replace(
        /"[^"]*"|'[^']*'|\b(?:D{1,4}|M{1,4}|YY(?:YY)?|([hHmstTw])\1?|[lLZ])\b/g,
        function ($0) {
            switch ($0) {
                case 'D':
                    return date.getDate();
                case 'DD':
                    return zeroize(date.getDate());
                case 'DDD':
                    return lang[locale].weekdaysShort[date.getDay()];
                case 'DDDD':
                    return lang[locale].weekdays[date.getDay()];
                case 'M':
                    return date.getMonth() + 1;
                case 'MM':
                    return zeroize(date.getMonth() + 1);
                case 'MMM':
                    return lang[locale].monthsShort[date.getMonth()];
                case 'MMMM':
                    return lang[locale].months[date.getMonth()];
                case 'YY':
                    return String(date.getFullYear()).substr(2);
                case 'YYYY':
                    return date.getFullYear();
                case 'h':
                    return date.getHours() % 12 || 12;
                case 'hh':
                    return zeroize(date.getHours() % 12 || 12);
                case 'H':
                    return date.getHours();
                case 'HH':
                    return zeroize(date.getHours());
                case 'm':
                    return date.getMinutes();
                case 'mm':
                    return zeroize(date.getMinutes());
                case 's':
                    return date.getSeconds();
                case 'ss':
                    return zeroize(date.getSeconds());
                case 'l':
                    return date.getMilliseconds();
                case 'll':
                    return zeroize(date.getMilliseconds());
                case 'tt':
                    return date.getHours() < 12 ?
                        lang[locale].time_tt_a :
                        lang[locale].time_tt_p;
                case 'TT':
                    return date.getHours() < 12 ?
                        lang[locale].time_TT_a :
                        lang[locale].time_TT_p;
                case 'w':
                    var target = new Date(date.valueOf());
                    var dayNr = (date.getDay() + 7) % 7;
                    target.setDate(target.getDate() - dayNr + 3);
                    var firstThursday = target.valueOf();
                    target.setMonth(0, 1);
                    if (target.getDay() != 4) {
                        target.setMonth(0, 1 + ((4 - target.getDay()) + 7) % 7);
                    }
                    let weekNum = 1 + Math.ceil((firstThursday - target) / 604800000);
                    return lang[locale].week(weekNum);
            }
        }
    );
};
