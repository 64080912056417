<template>
    <ul class="dropdown-menu" v-show="showPopper" :style="poperStyle">
        <slot></slot>
    </ul>
</template>
<script>
export default {
    name: 'dropdown-menu',
    data() {
        return {
            showPopper: false,
            poperStyle: {}
        }
    },
    inject: ['dropdown'],
    watch: {
        showPopper(val) {
            if(val) {
                this.updatePoperStyle();
            }
        }
    },
    created() {
        this.$on('visible', val => {
            this.showPopper = val;
        });
    },
    mounted() {
        this.dropdown.popperElm = this.popperElm = this.$el;
        this.dropdown.initDomOperation();
    },
    methods: {
        updatePoperStyle() {
            let prevElm = this.$el.previousElementSibling || this.$el.previousSibling;
            let elmRect = prevElm.getBoundingClientRect();
            this.poperStyle = {
                top: elmRect.height + 'px',
                left: prevElm.offsetLeft + 'px'
            }
        },
    }
}
</script>